<template>
  <div id="_2022_2">
    <div id="Announcement-IGU-MGSC-Awards-2022">
      <h3><strong class="home-h">Announcement of IGU MGSC Awards 2022</strong></h3>
      <hr>
      <p>After careful consideration and selection by the awards committee, the IGU MGSC Awards 2022 were announced.</p>
      <p>These awards were established by the Modeling Geographical Systems Commission of IGU. They aim to recognize outstanding academic contributions in the field of geographic modeling research and promote innovation and development in modeling geographic systems.</p>
      <p>This time, one winner of the Outstanding Achievement Award (OAA) and two winners of the Excellent Young Scholar Award (YSA) were selected. The awardee list is as follows:</p>

      <h5><strong>OAA in Modeling Geographical Systems</strong></h5>

      <div class="pic">
        <img style="max-width: 80%;" src="../../../assets/static/img/Members/Michael Batty.jpg"><br><br>
        <p><strong>Prof. Michael Batty</strong></p>
      </div>
      <p>Prof. Michael Batty is Bartlett Professor of Planning at University College London where he is Chair of the Centre for Advanced Spatial Analysis (CASA). He has worked on computer models of cities and their visualisation since the 1970s and has published several books, such as Cities and Complexity (MIT Press, 2005) which won the Alonso Prize of the Regional Science Association in 2011, and most recently The New Science of Cities (MIT Press, 2013). His blogs www.complexcity.info cover the science underpinning the technology of cities and his posts and lectures on big data and smart cities are at www.spatialcomplexity.info. His research group is working on simulating long term structural change and dynamics in cities as well as their visualisation. He is a Fellow of the British Academy (FBA), the Academy of Social Sciences (FAcSS) and the Royal Society (FRS), was awarded the CBE in the Queen’s Birthday Honours in 2004 and the 2013 recipient of the Lauréat Prix International de Géographie Vautrin Lud (generally known as the 'Nobel de Géographie'). This year 2015 he received the Founders Medal of the Royal Geographical Society for his work on the science of cities. In 2016 he received the Gold Medal of the Royal Town Planning Institute, and the Senior Scholars Award of the Complex Systems Society.</p>

      <h5><strong>YSA in Modeling Geographical Systems</strong></h5>

      <div class="pic">
        <img style="max-width: 80%;" src="../../../assets/static/img/Members/Christopher Higgins.jpg"><br><br>
        <p><strong>Dr. Christopher Higgins</strong></p>
      </div>
      <p>Dr. Christopher Higgins is an Assistant Professor in the Department of Human Geography at University of Toronto. He is a quantitative geographer whose research is focused on the relationship between form and function in cities. Using the tools and methods of GIS/geographic data science, he captures and models the physical form and infrastructure of the city, its urban networks and the flows of people, goods, and information they facilitate, and the use of the city as an area or volume for engaging in activities. By providing a window into how the dynamic state of the city impacts and is impacted by social, environmental, and economic processes, his work seeks to better inform policy and planning interventions and promote more sustainable urban outcomes.</p>

      <br>

      <div class="pic">
        <img style="max-width: 80%;" src="../../../assets/static/img/Members/Li He.jpg"><br><br>
        <p><strong>Dr. Li He</strong></p>
      </div>
      <p>Dr. Li He is currently an Associate Professor in the School of Humanities & Social Science at Xi’an Jiaotong University. He gives full play to his GIS background and takes spatial social computing as his research direction. He studies urban violent crime, theft crime, fraud crime, air pollution, health, population mobility, refined social governance and other issues from the interdisciplinary perspectives of sociology, geography and big data. He uses cutting-edge methods such as spatio-temporal big data modeling, mobile data mining, GIS spatio-temporal analysis and computational social science to actively explore the factors that affect the safety and well-being of residents in urban environment. Through the combination of multi-source big data and empirical analysis, he is committed to exploring the spatial and temporal relationship between residents’ daily activities, safety risks and physical/mental health, and revealing the improvement path of residents’ safety and health.</p>

    </div>
  </div>
</template>

<script>
export default {
  name: '_2022_2'
}
</script>

<style lang="less" scoped>
#_2022_2 {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  line-height: 2rem;
  text-align: justify;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

h5 {
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
  color: #1a80b6;
}

.home-date {
  font-weight: bold;
  color: grey;
}

a {
  text-decoration: none;
}

.pic {
  width: 100rem;
  display: table-cell;
  text-align: center;
}
</style>