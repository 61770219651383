<template>
  <div id="MembersPapers">
    <div>
      <div>
        <h3>Members' Papers</h3>
        <hr>
        <div>
          <P>Coming soon...</P>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MembersPapers'
}
</script>

<style lang="less" scoped>
#MembersPapers {
  font-size: 1.2rem;
  line-height: 2rem;
  height: 50rem;
  text-align: justify;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
}

h3 {
  color: #1a80b6;
  font-size: 20px;
  font-weight: 1000;
}

hr {
  border: 0.1px solid;
  border-color: #eeeeee;
}
</style>