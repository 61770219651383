<template>
  <div id="_2021_6">
    <div id="Minutes-of-the-second" class="cos-modal">
      <h3><strong class="home-h">Minutes of the second board meeting of Modeling
          Geographical Systems Commission (MGSC)</strong></h3>
      <small class="home-date">Sep 20th, 2021 <br></small>
      <hr>
      <p>
        <strong>Time: </strong>8:00pm, Sept 20, 2021 (Beijing Time)<br>

        <strong>Venue: </strong>Online via Zoom<br>
        <strong>Attendees</strong> (Alphabetically ordered by last name):

      <ol>
        <li>Prof. Min Chen</li>
        <li>Prof. Christophe Claramunt</li>
        <li>Prof. Barry Croke</li>
        <li>Dr. Manish Kumar</li>
        <li>Prof. Xin Li</li>
        <li>Dr. Xintao Liu</li>
        <li>Prof. Guonian Lü</li>
        <li>Dr. Zaiyang Ma</li>
        <li>Dr. Erik Nixdorf</li>
        <li>Prof. Paul Torrens</li>
        <li>Prof. Rumiana Vatseva</li>
      </ol>
      Prof. Min Chen in the meeting introduced the events and news that
      happened this year, followed by discussing with the board members about
      the future activities. The major topics and discussion are shown below:
      </p>
      <p>
      <ol>
        <li><b>The progress in member registration.</b>
          <ul>
            <li>There are 88 scholars and students registered as
              IGU-MGSC members.</li>
          </ul>
        </li>
        <li><b>Review of important events</b>
          <ul>
            <li> The 2nd national forum on geographic modeling and
              simulation (and the launch ceremony of the training
              course on Chinese geographical analysis models)
              supported by IGU-MGSC on January 16-17, 2021.</li>
            <li>Start calling for new members from May 2021.</li>
            <li>The first training course on Chinese geographical
              analysis models supported by IGU-MGSC on August 9-16,
              2021.</li>
            <li>IGU 19th International Conference on Spatial Data
              Handling and Geographic Intelligence co-organized by
              MGSC on August 13-14, 2021.</li>
            <li>The 2nd International Geography and Sustainability
              Workshop (online) co-organized by MGSC will be held on
              November 23-24, 2021.</li>
          </ul>
        </li>
        <li><b>Special issues</b>
          <ul>
            <li>Applied Energy | SI: Energy digitization with spatial
              intelligence</li>
            <li>Transactions in GIS | SI: Virtual Geographic Environment
            </li>
            <li>Frontiers in Public Health (Environmental Health and
              Exposome) | SI: Smart Urban Environmental Health from
              Multi-Scale, Multimedia, Multi-Exposure, Multi-Target
              Perspectives</li>
            <li>Water | SI: Advances in Hydroinformatics for Water Data
              Management and Analysis</li>
          </ul>
        </li>
        <li><b>Calling for new members</b>
          <ul>
            <li>Most of our members are from Asia and Europe. We should
              call for more members from other regions, like North
              America.</li>
            <li>Inviting the people who attend the conference or webinar
              to be our members.</li>
          </ul>
        </li>
        <li><b>Collecting news on geographic modeling and simulation and
            spreading to our colleagues via our website and mail
            list</b>
          <ul>
            <li>We are collecting messages regarding the topic of
              IGU-MGSC or any conferences, special issues, and job
              positions in the geographic modeling field. These
              messages will be posted on our website and spread to our
              members through group mails every 3 months.</li>
            <li>We can post our news through
              Twitter/Facebook/Researchgate.</li>
          </ul>
        </li>
        <li><b>Hosting or organizing conferences, workshops, and webinar</b>
          <ul>
            <li>Do we organize/co-organize a session during the IGU-UGI
              Centennial Congress in Paris (July 18-22, 2022) ?</li>
          </ul>
          <img src="../../../assets/static/img/Events/Minutes-of-the-second-1.png" style="width: 100%">
          <ul>
            <li>The speakers of the webinar: the honorary advisory
              members, the board members, award winners.</li>
          </ul>
        </li>
        <li><b>Four awards by committee meeting: Best Paper, Excellent Young
            Scholar, Distinguished Scholar and Distinguished
            Service.</b>
          <ul>
            <li>Calling for three awards this year: Best Paper Award
              (journal paper), Excellent Young Scholar Award, and
              Distinguished Scholar Award.</li>
            <li>Award preparation: (1) calling for award nominations,
              (2) organizing award committee, and (3) announcing award
              winners.</li>
          </ul>
        </li>
        <li><b>Any other business?</b>
          <ul>
            <li>Updating the activities and news on the website.
            </li>
          </ul>
        </li>
        <li><b>Online photo of all attendees was taken.</b><br>
          <img src="../../../assets/static/img/Events/Minutes-of-the-second-2.png" style="width: 100%">
        </li>

      </ol>
      </p>

    </div>
  </div>
</template>

<script>
export default {
  name: '_2021_6'
}
</script>

<style lang="less" scoped>
#_2021_6 {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  line-height: 2rem;
  text-align: justify;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

.home-date {
  font-weight: bold;
  color: grey;
}

li {
  line-height: 2.3rem;
}

p {
  line-height: 2.2rem;
}
</style>