<template>
  <div id="Events">
    <div>
      <el-tabs tab-position="left" v-model="activeTabName">
        <el-tab-pane label="2024" name="2024">
          <div class="list-group">
            <a href="#" class="list-group-item active"> 2024 Events </a>
            <router-link to="/Events/2024/2">
              <a href="#" class="list-group-item"
                >Call for Nominations - IGU Outstanding Achievement Award and
                Excellent Young Scholar Award in Modeling Geographical Systems
                (2024)</a
              >
            </router-link>
            <router-link to="/Events/2024/1">
              <a href="#" class="list-group-item"
                >The 3rd International Workshop on Geographic Modeling and
                Simulation: Modeling and Simulation for A Sustainable World</a
              >
            </router-link>
          </div>
        </el-tab-pane>
        <el-tab-pane label="2023" name="2023">
          <div class="list-group">
            <a href="#" class="list-group-item active"> 2023 Events </a>
            <router-link to="/Events/2023/3">
              <a href="#" class="list-group-item"
                >Call for abstracts | IGC 2024 Modeling and Simulating
                Geographical Systems in the Big Data Era session</a
              >
            </router-link>
            <router-link to="/Events/2023/2">
              <a href="#" class="list-group-item"
                >Announcement of IGU MGSC Awards 2023</a
              >
            </router-link>
            <router-link to="/Events/2023/1">
              <a href="#" class="list-group-item"
                >Minutes of Modeling Geographical Systems Commission (MGSC)
                board meeting</a
              >
            </router-link>
          </div>
        </el-tab-pane>

        <el-tab-pane label="2022" name="2022">
          <div class="list-group">
            <a href="#" class="list-group-item active"> 2022 Events </a>
            <router-link to="/Events/2022/5">
              <a href="#" class="list-group-item"
                >2022 Annual Academic Conference of the Geographic Modeling and
                Geographic Information Analysis Committee of the Chinese
                Geographical Society</a
              >
            </router-link>
            <router-link to="/Events/2022/3">
              <a href="#" class="list-group-item"
                >The second training course on Chinese geographical analysis
                models</a
              >
            </router-link>
            <router-link to="/Events/2022/4">
              <a href="#" class="list-group-item"
                >The session of Geographic information modelling and simulation
                in the era of big data in IGU Paris 2022</a
              >
            </router-link>
            <router-link to="/Events/2022/2">
              <a href="#" class="list-group-item"
                >Announcement of IGU MGSC Awards 2022</a
              >
            </router-link>

            <router-link to="/Events/2022/1">
              <a href="#" class="list-group-item"
                >Call for Nominations - IGU Outstanding Achievement Award and
                Excellent Young Scholar Award in Modeling Geographical Systems
                (2022)</a
              >
            </router-link>
          </div>
        </el-tab-pane>

        <el-tab-pane label="2021" name="2021">
          <div class="list-group">
            <a href="#" class="list-group-item active"> 2021 Events </a>

            <router-link to="/Events/2021/6">
              <a href="#" class="list-group-item"
                >Minutes of the second board meeting of Modeling Geographical
                Systems Commission (MGSC)</a
              >
            </router-link>

            <router-link to="/Events/2021/5">
              <a href="#" class="list-group-item"
                >IGU 19th International Conference on Spatial Data Handling and
                Geographic Intelligence</a
              >
            </router-link>

            <router-link to="/Events/2021/4">
              <a href="#" class="list-group-item"
                >The first training course on Chinese geographical analysis
                models</a
              >
            </router-link>

            <router-link to="/Events/2021/3">
              <a href="#" class="list-group-item"
                >Minutes of the first board meeting of Modeling Geographical
                Systems Commission (MGSC)</a
              >
            </router-link>

            <router-link to="/Events/2021/2">
              <a href="#" class="list-group-item"
                >The 2nd national forum on geographic modeling and simulation
                (and the launch ceremony of the training course on domestic
                geographical analysis models)</a
              >
            </router-link>

            <router-link to="/Events/2021/1">
              <a href="#" class="list-group-item"
                >Call for Abstracts | The session of Geographic information
                modelling and simulation in the era of big data in IGU Paris
                2022</a
              >
            </router-link>
          </div>
        </el-tab-pane>

        <el-tab-pane label="2020" name="2020">
          <div class="list-group">
            <a href="#" class="list-group-item active"> 2020 Events </a>
            <router-link to="/Events/2020/1">
              <a href="#" class="list-group-item"
                >The general election of Modeling Geographical Systems
                Commission (MGSC) of International Geographical Union (IGU)</a
              >
            </router-link>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Events',
  data() {
    return {
      value: '',
      activeTabName: '2024'
    };
  }
};
</script>

<style lang="less" scoped>
#Events {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3px;
  margin-top: 2.2rem;
  height: 700px;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

// vs-select {
//   width: 150%;
// }
.vs-select-content {
  max-width: 6rem;
  margin-left: -7rem;
}

.list-group-item {
  font-size: 1rem;
  font-weight: bold;
}

.list-group-item.active {
  background-color: #1a80b6;
  border-color: #1a80b6;
}

a {
  text-decoration: none;
}
</style>
