<template>
  <div id="bottom">
    <div>
      <span>Copyright &copy;2023 Nanjing Normal University</span><br>
      <span><a style="color: white" target="_blank" href="https://beian.miit.gov.cn">陕ICP备2020015416号</a></span>
    </div>

  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
#bottom {
  background-color: #201f23;
  margin-top: 0.8rem;
  text-align: center;
  line-height: 1.3rem;
  font-size: 0.6rem;
  word-spacing: 0.3rem;
}

span {
  font-family: arial;
  color: white;
  font-size: 0.8rem;
}
</style>