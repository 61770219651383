<template>
  <div id="_2022_5">
    <div id="Call-for-Nominations">
      <h3><strong class="home-h">Minutes of Modeling Geographical Systems Commission (MGSC) board meeting
        </strong>
      </h3>

      <hr>
      <p>
        <strong>Time:</strong> 10:00pm, Jan 10th 2023 (Beijing Time) <br>
        <strong>Venue:</strong> Online via Zoom<br>
        <strong>Attendees</strong> (Alphabetically ordered by last name):<br>
        Prof. Min Chen (Chair), Prof. Wei Luo (Secretary) <br>
        Prof. Mike Batty, Prof. Tao Cheng, Prof. Christophe Claramunt, Prof. Barry Croke, Prof. Manish Kumar, Prof. Mei-Po Kwan, Prof. Guonian Lv, Dr. Zaiyang Ma, Prof. Antonio Páez, Prof. Karel Staněk, Prof. Paul Torrens, Prof. Rumiana Vatseva
      </p>
      <p>The board committee of Modeling Geographical Systems Commission of IGU (IGU MGSC) held the 2022 Annual Meeting of Board Members. There are 14 participants in total. First of all, Prof. Wei Luo and Prof. Min Chen reported the progress in member registration and events in 2022, and discussed future activities. Prof. Min Chen also highlighted the recent publications on position papers from MGSC committee and encouraged more similar work to lead the future directions of geographical modeling and simulation field. After that, all of the committee provided very useful feedback and comments for what we have done in the past year and what we could do in the future. The meeting photo of all attendees is taken below.
      </p>

      <div class="pic">
        <img style="max-width: 80%;" src="../../../assets/static/img/Events/20231.png"><br><br>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '_2022_5'
}
</script>

<style lang="less" scoped>
#_2022_5 {
  height: 1180px;
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  line-height: 2rem;
  text-align: justify;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

h4 {
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
}

.home-date {
  font-weight: bold;
  color: grey;
}

a {
  text-decoration: none;
}

.pic {
  // display: table-cell;
  text-align: center;
}
</style>