<!--
 * @Author: ZhangS
 * @LastEditors: ZhangS
 * @Date: 2023-11-06 16:18:29
 * @LastEditTime: 2023-11-06 16:35:48
 * @Description: 
-->
<template>
  <div id="top">
    <div>
      <a href="http://www.igu-geomodeling.com/"><img class="Title" src="../assets/static/img/logo/KeyLogo.png"></a>
    </div>
    <div class="text-space">
      <p>Modeling Geographical Systems Commission (MGSC)</p>
      <p>International Geographical Union (IGU)</p>
      <p class="socialMedia">
        Social Media: &nbsp;&nbsp;

        <a href="https://twitter.com/IGU__MGSC" target="_blank">
          <svg t="1670831736288" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2167" width="120" height="120">
            <path d="M295.2 338c120.2 61 221.7 57.4 221.7 57.4s-38.5-134.8 80.5-194.6 200.9 41 200.9 41 20.8-5.7 36.2-11.4c15.5-5.7 37.8-15.9 37.8-15.9l-36.7 65.8 56.5-6s-7 10.2-29.6 31-31.9 31.6-31.9 31.6 8.1 162.3-77 287.2C668.5 749 558.6 824 398.9 839.8c-159.7 15.8-263.7-49.2-263.7-49.2s69.8-4 114.3-21.1S358 707.4 358 707.4s-91-28.1-123.7-59.8c-32.7-31.6-40.8-50.4-40.8-50.4l89.8-1.2s-94.5-50.4-121.3-90.3-30.3-78.5-30.3-78.5l69.1 28s-57.4-78.4-65.6-139.4c-8.2-61 10.5-93.8 10.5-93.8s29.3 55 149.5 116z" fill="#5DD7FC" p-id="2168"></path>
          </svg>
        </a>

        &nbsp;&nbsp;&nbsp;

        <a href="https://www.linkedin.com/in/igu-mgsc-aba66925a/" target="_blank">
          <svg t="1670831784529" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2398" width="120" height="120">
            <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8z m-59.3-434c-38 0-68.8-30.8-68.8-68.8s30.8-68.8 68.8-68.8c37.9 0 68.8 30.8 68.8 68.8-0.1 38-30.9 68.8-68.8 68.8z m503.7 434H675.1V608c0-44.3-0.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" p-id="2399" data-spm-anchor-id="a313x.7781069.0.i2" class="" fill="#5dd7fc"></path>
          </svg>
        </a>

      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Top'
}
</script>

<style lang="less" scoped>
#top {
  margin-top: 1.3rem;
  text-align: center;
  line-height: 1.3rem;
  font-size: 1.5rem;
  word-spacing: 0.2rem;
}

.text-space {
  margin-top: 0.6rem;
}

.socialMedia {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

svg {
  width: 30px;
  height: 30px;
  transition: all 0.2s;

  &:hover {
    transform: scale(1.2);
  }
}
</style>