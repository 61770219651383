<template>
  <div id="_2022_5">
    <div id="Call-for-Nominations">
      <h3><strong class="home-h">2022 Annual Academic Conference of the Geographic Modeling and Geographic Information Analysis Committee of the Chinese Geographical Society
        </strong></h3>
      <hr>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;The 2022 Annual Academic Conference of the Geographic Modeling and Geographic Information Analysis Committee was held in Nanjing, Jiangsu, China, from September 17 to September 18, 2022. The theme of the conference is "Geographic Information Modeling and Simulation in the New Era of Geography - Exploration and Practice", and the conference aims to explore new opportunities, new positions, and new paradigms for the development of geographic information modeling and geographic information analysis in the interdisciplinary context. This conference is organized by the Geographic Modeling and Geographic Analysis Committee of the Geographical Society of China, hosted by the School of Geography and Key Laboratory of Virtual Geographic Environment, Nanjing Normal University. Modeling Geographical Systems Commission of International Geographical Union (IGU MGSC) is one of the co-organizations.
      </p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;The opening ceremony of the conference was held on the morning of September 17. It was hosted by the Honorary Advisory Member of MGSC, Prof. Guonian Lü. Another Honorary Advisory Member, Prof. Jinfeng Wang and several other professors, including Prof. Linwang Yuan, the Vice President of Nanjing Normal University, Prof. Guoyou Zhang, the Vice President and Secretary General of the Geographical Society of China, and Prof. Michael Barton of Arizona State University, the President of the Open Modeling Foundation, delivered speeches.
      </p>

      <div class="pic">
        <img style="max-width: 80%;" src="../../../assets/static/img/Events/conference1.png"><br><br>
      </div>

      <p>&nbsp;&nbsp;&nbsp;&nbsp;During the conference, the Academicians of CAS, including Prof. Chenghu Zhou, Prof. Yongjiu Dai, Prof. Fahu Chen, Prof. Bojie Fu, Prof. Peng Cui, Prof. Jun Xia, Prof. Huijun Wang and Prof. Huijun Wang attended and gave the keynote speeches. The Chair and Honorary Advisory Member of MGSC, including Prof. Min Chen, Prof. Jinfeng Wang and Prof. Xin Li, as well as other excellent experts, such as Director Prof. Juhua Xiong from the Department of Earth Sciences of the National Natural Science Foundation of China, Prof. Yu Liu from Peking University, Prof. Xiaoping Liu from Sun Yat-sen University, also did achievements sharing through the keynote speeches on geographical system modeling. Moreover, the 14 sessions were also conducted to discuss 14 hot topics respectively, for example, "Geographic Modeling Theory and System Construction Methods", "Geographic Modeling and Spatial Data Analysis Teaching Research", "Earth System Model Theory, Analysis and Application", "Crime Geography Theory and Modeling", "Spatial and Temporal Analysis of Environment and Health", "Healthy City Modeling ".
      </p>
      <div class="pic">
        <img style="max-width: 80%;" src="../../../assets/static/img/Events/conference2.png"><br><br>
      </div>
      <div class="pic">
        <img style="max-width: 80%;" src="../../../assets/static/img/Events/conference3.png"><br><br>
      </div>

      <p>&nbsp;&nbsp;&nbsp;&nbsp;The conference attracted more than 300 experts and scholars from nearly 70 universities and research institutes in China, including more than 180 offline participants and more than 120 online participants and presenters. This conference effectively promoted mutual learning and exchange among experts and scholars from all over China and enhanced the friendship among the participants through 150 geographic modeling-related presentations.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: '_2022_5'
}
</script>

<style lang="less" scoped>
#_2022_5 {
  height: 2700px;
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  line-height: 2rem;
  text-align: justify;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

h4 {
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
}

.home-date {
  font-weight: bold;
  color: grey;
}

a {
  text-decoration: none;
}

.pic {
  // display: table-cell;
  text-align: center;
}
</style>