<template>
  <div id="_2020_1">
    <div id="The-general-election" class="cos-modal">
      <h3><strong>The general election of Modeling Geographical Systems Commission (MGSC) of International Geographical Union (IGU)</strong></h3>
      <small class="home-date">September 29, 2020</small>
      <hr>
      <p> &nbsp;&nbsp;&nbsp;&nbsp;The general election of Modeling Geographical Systems Commission (MGSC) of International Geographical Union (IGU), was
        a success. At the invitation of the International Geographical Union, Professor Chen Min of Nanjing Normal University
        will be the chairman of the Modeling Geographical Systems commission. At the same time, the IGU of MGSC selected a
        Secretary-General, 10 steering committee members and 10 Honorary advisory members. <br></p>
    </div>
  </div>
</template>

<script>
export default {
  name: '2020-1'
}
</script>

<style lang="less" scoped>
#_2020_1 {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  line-height: 2rem;
  height: 50rem;
  text-align: justify;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

.home-date {
  font-weight: bold;
  color: grey;
}
</style>