<!--
 * @Author: ZhangS
 * @LastEditors: LIFM0623 1944099171@qq.com
 * @Date: 2023-11-06 16:36:55
 * @LastEditTime: 2023-11-16 14:45:33
 * @Description: 
-->
<template>
    <div id="_2022_5">
      <div id="Call-for-Nominations">
        <h3><strong class="home-h">Call for abstracts | IGC 2024 Modeling and Simulating Geographical Systems in the Big Data Era session
          </strong>
        </h3>
  
        <hr>
        <p>
            The 35th International Geographical Congress (IGC 2024) will be held in Dublin, Ireland from 24 to 30 August 2024. Around the theme "Celebrating a World of Difference" of the congress, 
            the Modeling Geographical Systems Commission (MGSC) of IGU will organize a session–<strong>Modeling and Simulating Geographical Systems in the Big Data Era</strong> –to share the research achievements, discuss common challenges and opportunities, and connect with colleagues across the world in this field.
        </p>
        <div class="pic">
            <img style="max-width: 80%;" src="../../../assets/static/img/Events/Call for abstracts.png">
        </div>
        <strong>
            Session description
        </strong>
        <p>
            In the age of Internet, big data has become an important resource of human society. It is growing exponentially and rapidly that affects the development and transformation of human society. Big data era also brings new opportunities to the development of geography in terms of modeling and simulating geographical systems. On one hand, we need to find a way to invert the process of changes in the earth’s surface and establish a geoscience information map through spatiotemporal digital construction based on computational science. On the other hand, with the support of big data and artificial intelligence, we need to explore the complex laws of earth surface changes relying on the professional knowledge of geosciences, through which we can discover new geoscience knowledge and realize holographic intelligent planning and prediction of the future. For example, the modern world is facing diverse challenges and risks such as extreme weather events, trade and technology wars, geopolitical tensions, and COVID-19. To tackle those challenges with uncertainty, geographic modeling has been put forward with higher requirements in terms of geographic detection, spatiotemporal diffusion laws/mechanisms, dissemination of early warnings, resilience analysis, online public opinion monitoring, etc.
        </p>
        <p>
            This session aims to discuss and explore related theories, methods and potential practices, facilitating communication between experts from different domains, encouraging extensive discussion regarding the potential directions of the field, and promoting further research for a bright future.
        </p>
        <p>
            We will hold <strong>the ceremony for IGU Outstanding Achievement Award and Excellent Young Scholar Award in Modeling Geographical Systems 2023.</strong> 
        </p>
        <br>
        <strong>Now, the call for abstracts is open! It is my absolute pleasure to invite you to submit your abstracts for oral presentations.</strong>
        <br>
        <br>
        <strong>Summary of Minimum Requirements</strong>
        <br>
        Title: 25 words maximum.
        <br>Abstract: 250 words maximum.
        <br>Congress Commission and Session: Please select <strong>Modeling Geographical Systems Commission</strong> and the associated sessions.
        <br>Also required: Up to five keywords.
        <br><br>
        <p>Full details about submission: <a href="https://igc2024dublin.org/call-for-abstracts/">https://igc2024dublin.org/call-for-abstracts/</a>.</p>
        <br>
        <strong>Important deadlines</strong>
        <br>The deadline for the abstract proposals is <span style="color: red;font-weight: 600;">Friday 12th January 2024.</span>
        <br>The announcement of the accepted abstracts is expected by <span style="color: red;font-weight: 600;">Monday 19th February 2024</span>.
        <br>
        <br>
        <p>For other information about IGC 2024, please see <a href="https://igc2024dublin.org/">https://igc2024dublin.org/</a>.</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: '_2022_5'
  }
  </script>
  
  <style lang="less" scoped>
  #_2022_5 {
    height: fit-content;
    font-size: 1.2rem;
    line-height: 2rem;
    width: 65%;
    margin: 0 auto;
    position: relative;
    word-spacing: 0.3rem;
    margin-top: 2.2rem;
    line-height: 2rem;
    text-align: justify;
  }
  
  h3 {
    color: #1a80b6;
    font-size: 1.3rem;
    font-weight: 1000;
  }
  
  h4 {
    margin-top: 0.9rem;
    margin-bottom: 0.9rem;
  }
  
  .home-date {
    font-weight: bold;
    color: grey;
  }
  
  a {
    text-decoration: none;
  }
  
  .pic {
    // display: table-cell;
    text-align: center;
  }
  .flex{
    display: flex;
    // justify-content: center;
  }
  </style>