<template>
  <div id="Journal">
    <div id="Journals">
      <div class="container">
        <h3>Journals</h3>
        <hr>

        <div class="row">
          <div class="col-4">
            <a target="_blank" href="https://www.sciencedirect.com/journal/earth-science-reviews"><img class="img-responsive" src="../../assets/static/img/Journals/Earth-Science Reviews.jpg"></a>
          </div>

          <div class="col-8">
            <div>
              <strong><a target="_blank" href="https://www.sciencedirect.com/journal/earth-science-reviews">Earth-Science Reviews </a></strong><br>
              <p>
                <label>ISSN</label>: 0012-8252<br>
                <label>Impact Factor</label>: 12.1 (2022) <br>
                <label>Description</label>: Covering a much wider field than the usual specialist journals, Earth Science Reviews publishes review articles dealing with all aspects of Earth Sciences, and is an important vehicle for allowing readers to see their particular interest related to the Earth Sciences as a whole.<br>
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-md-4">
            <a target="_blank" href="https://www.sciencedirect.com/journal/environmental-modelling-and-software"><img class="img-responsive" src="../../assets/static/img/Journals/ENVIRON MODELL SOFTW.jpg"></a>
          </div>

          <div class='col-md-8'>
            <div>
              <strong><a target="_blank" href="https://www.sciencedirect.com/journal/environmental-modelling-and-software">Environmental Modelling & Software</a></strong><br>
              <p>
                <label>ISSN</label>: 1364-8152<br>
                <label>Impact Factor</label>: 4.9 (2022) <br>
                <label>Description</label>: Environmental Modelling & Software publishes contributions, in the form of research articles, reviews, introductory overviews, and position papers on advances in the area of environmental modelling and software. Our aim is to improve our capacity to represent, understand, predict or manage the behaviour of natural environmental systems, including air, water, and land components, at all practical scales, and to communicate those improvements to a wide scientific and professional audience.<br>
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-4'>
            <a target="_blank" href="https://www.journals.elsevier.com/computers-environment-and-urban-systems"><img class="img-responsive" src="../../assets/static/img/Journals/ComputersEnvironmentandUrbanSystems.jpg"></a>
          </div>

          <div class='col-md-8'>
            <div>
              <strong><a target="_blank" href="https://www.journals.elsevier.com/computers-environment-and-urban-systems">Computers, Environment and Urban Systems</a></strong><br>
              <p>
                <label>ISSN</label>: 0198-9715<br>
                <label>Impact Factor</label>: 6.8 (2022) <br>
                <label>Description</label>: Computers, Environment and Urban Systems is an interdisciplinary journal publishing cutting-edge and innovative computer-based research on urban systems, systems of cities, and built and natural environments , that privileges the geospatial perspective. The journal provides a stimulating presentation of perspectives, research developments, overviews of important new technologies and uses of major computational, information-based, and visualization innovations.
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-4'>
            <a target="_blank" href="https://www.mdpi.com/journal/remotesensing"><img class="img-responsive" src="../../assets/static/img/Journals/remotesensing.jpg"></a>
          </div>

          <div class='col-md-8'>
            <div>
              <strong><a target="_blank" href="https://www.mdpi.com/journal/remotesensing">Remote Sensing</a></strong><br>
              <p>
                <label>ISSN</label>: 2072-4292<br>
                <label>Impact Factor</label>: 5.0 (2022) <br>
                <label>Description</label>: Remote sensing is a technology that engages electromagnetic sensors to measure and monitor changes in the earth’s surface and atmosphere. Normally this is accomplished through the use of a satellite or aircraft. Remote Sensing, in its third edition, seamlessly connects the art and science of earth remote sensing with the latest interpretative tools and techniques of computer-aided image processing. Newly expanded and updated, this edition delivers more of the applied scientific theory and practical results that helped the previous editions earn wide acclaim and become classroom and industry standards.<br>
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-4'>
            <a target="_blank" href="https://ieeexplore.ieee.org/xpl/RecentIssue.jsp?reload=true&punumber=8859"><img class="img-responsive" src="../../assets/static/img/Journals//groRSL.jpg"></a>
          </div>

          <div class='col-md-8'>
            <div>
              <strong><a target="_blank" href="https://ieeexplore.ieee.org/xpl/RecentIssue.jsp?reload=true&punumber=8859">IEEE Geoscience and Remote Sensing Letters</a></strong><br>
              <p>
                <label>ISSN</label>: 1545-598X<br>
                <label>Impact Factor</label>: 4.8 (2022) <br>
                <label>Description</label>: IEEE Geoscience and Remote Sensing Letters (GRSL) is a monthly
                publication for short papers (maximum length 5 pages) addressing new ideas and formative
                concepts in remote sensing as well as important new and timely results and concepts. Papers
                should relate to the theory, concepts and techniques of science and engineering as applied to
                sensing the earth, oceans, atmosphere, and space, and the processing, interpretation, and dissemination
                of this information. <br>
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-4'>
            <a target="_blank" href="https://www.tandfonline.com/toc/tgis20/current"><img class="img-responsive" src="../../assets/static/img/Journals//IJGIS.jpg"></a>
          </div>

          <div class="col-md-8">
            <div>
              <strong><a target="_blank" href="https://www.tandfonline.com/toc/tgis20/current">International Journal of Geographical Information Science</a></strong><br>
              <p>
                <label>ISSN</label>: 1365-8816<br>
                <label>Impact Factor</label>: 5.7 (2022) <br>
                <label>Description</label>: The aim of International Journal of Geographical Information Science is to provide a forum for the exchange of original ideas, approaches, methods and experiences in the field of GIScience.
                International Journal of Geographical Information Science covers the following topics:Innovations and novel applications of GIScience in natural resources, social systems and the built environment Relevant developments in computer science, cartography, surveying, geography, and engineering Fundamental and computational issues of geographic information
                The design, implementation and use of geographical information for monitoring, prediction and decision making.
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-4'>
            <a target="_blank" href="https://www.tandfonline.com/toc/tjde20/current"><img class="img-responsive" src="../../assets/static/img/Journals/IJDE.jpg"></a>
          </div>

          <div class='col-md-8'>
            <div>
              <strong><a target="_blank" href="https://www.tandfonline.com/toc/tjde20/current">International Journal of Digital Earth</a></strong><br>
              <p>
                <label>ISSN</label>: 1753-8947<br>
                <label>Impact Factor</label>: 5.1 (2022) <br>
                <label>Description</label>: International Journal of Digital Earth is a journal focusing on the theories, technologies, applications and societal implications of Digital Earth.
                International Journal of Digital Earth is the official journal of the International Society for Digital Earth.
                The journal is a response to the Digital Earth initiative and it's aim to improve social conditions, protect the environment, and support future sustainable development.<br>
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-4'>
            <a target="_blank" href="https://journals.sagepub.com/home/epb"><img class="img-responsive" src="../../assets/static/img/Journals/Environmentand.png"></a>
          </div>

          <div class='col-md-8'>
            <div>
              <strong><a target="_blank" href="https://journals.sagepub.com/home/epb">Environment and Planning B: Urban Analytics and City Science</a></strong><br>
              <p>
                <label>ISSN</label>: 2399-8083<br>
                <label>Impact Factor</label>: 3.5 (2022) <br>
                <label>Description</label>: Environment and Planning B: Urban Analytics
                and City Science is the leading journal for the publication of high-quality articles that
                present cutting-edge research in analytical methods for urban planning and design. The journal
                focuses on smart cities, urban analytics, GIS, and urban simulation models. It also deals with
                visualisation, computation, and formal design-based methods applicable to morphological processes
                and structures in cities and regions.
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-4'>
            <a target="_blank" href="https://onlinelibrary.wiley.com/journal/15384632"><img class="img-responsive" src="../../assets/static/img/Journals/geographicalanalysis.jpg"></a>
          </div>

          <div class='col-md-8'>
            <div class="m-fsz col-md-8 col-xs-12 m-center">
              <strong><a target="_blank" href="https://onlinelibrary.wiley.com/journal/15384632">Geographical Analysis </a></strong><br>
              <p>
                <label>ISSN</label>: 0016-7363<br>
                <label>Impact Factor</label>: 3.6 (2022) <br>
                <label>Description</label>: Geographical Analysis publishes geographical
                theory, model building, and quantitative methods to geographers and scholars in a wide spectrum of related fields.
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-4'>
            <a target="_blank" href="https://www.springer.com/journal/10109"><img class="img-responsive" src="../../assets/static/img/Journals/JournalofGeogra.jpg"></a>
          </div>

          <div class='col-md-8'>
            <div>
              <strong><a target="_blank" href="https://www.springer.com/journal/10109"> Journal of Geographical Systems </a></strong><br>
              <p>
                <label>ISSN</label>: 1435-5949 <br>
                <label>Impact Factor</label>: 2.9 (2022) <br>
                <label>Description</label>: The Journal of Geographical Systems (JGS) is an
                interdisciplinary peer-reviewed academic journal that aims to encourage and promote high-quality
                scholarship on new theoretical or empirical results, models and methods in the social sciences.
                It solicits original papers with a spatial dimension that can be of interest to social scientists.
              </p>
            </div>
          </div>
        </div>

        <hr>
        <div class="row">
          <div class='col-md-4'>
            <a target="_blank" href="https://www.springer.com/journal/43762"><img class="img-responsive" src="../../assets/static/img/Journals/ComputationalUrbanScience.jpg"></a>
          </div>

          <div class='col-md-8'>
            <div>
              <strong><a target="_blank" href="https://www.springer.com/journal/43762">Computational Urban Science</a></strong><br>
              <p>
                <label>ISSN</label>: 2730-6852<br>
                <!--                            <label class="stronglabel">Impact Factor</label>: 3.097 (2019) <br>-->
                <label>Description</label>: Computational Urban Science publishes rigorously peer-reviewed and high-quality original articles and reviews that focus on the intersection of computational sciences and urban sciences in building intelligent and resilient cities. The journal aims to introduce the latest results in urban computing and its applications, examine both the spatial and social dimension of urban networks and built environment, promote the cooperation between computational disciplines and the urban domain sciences, and build a bridge for scientific communication.
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-4'>
            <a target="_blank" href="https://www.tandfonline.com/toc/tagi20/current"><img class="img-responsive" src="../../assets/static/img/Journals/AnnualofGIS.jpg"></a>
          </div>

          <div class='col-md-8'>
            <div>
              <strong><a target="_blank" href="https://www.tandfonline.com/toc/tagi20/current">Annals of GIS</a></strong><br>
              <p>
                <label>Impact Factor</label>: 5 (2022) <br>
                <label>ISSN</label>: 1947-5683<br>
                <!--                            <label class="stronglabel">Impact Factor</label>: 3.097 (2019) <br>-->
                <label>Description</label>: Annals of GIS is an open access journal that publishes interdisciplinary research on theory, methods, development and applications in geo-information science.
                The journal aims to connect researchers in the region to one another and to the wider, international scientific community.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-backtop style="visibility-height:1400"></el-backtop>
  </div>
</template>

<script>
export default {
  name: 'Journal'
}
</script>

<style lang="less" scoped>
#Journal {
  font-size: 1.2rem;
  line-height: 2rem;
  text-align: justify;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

.img-responsive {
  display: block;
  margin-right: auto;
  margin-left: 3.3rem;
  margin-top: auto;
  max-width: 100%;
}

.vs-row {
  margin: 2.2rem 0;
}

p {
  font-size: 1.1rem;
  margin-bottom: 0px;
}

a {
  color: #1a80b6;
  text-decoration: none;
}

hr {
  border: 0.1px solid;
  border-color: #eeeeee;
}

label {
  font-weight: 600;
}

.row {
  align-items: center;
  margin: 2.2rem 0;
  line-height: 2rem;
}
</style>