<template>
  <div id="aboutUs">
    <div>
      <div>
        <h3>Mission of MGSC</h3>
        <hr>
        <div>
          <div id="mission">The commission will develop a medium-term research agenda for the
            commission, which will serve as the guideline for commission events and for community members
            to share ideas and research findings. Specifically, <br><br>
          </div>

          <ul>
            <li>Develop and maintain connections and joint efforts with other IGU groups as well as
              other
              related GIScience and geographic academic organizations.
            </li>
            <li>Organize special sessions and workshops in IGU conferences as well as other GIScience,
              or
              geography conferences, in accordance with the research agenda.
            </li>
            <li>Collaborate with commissions within IGU or other academic associations to organize joint
              workshops on topics of shared interest.
            </li>
          </ul>

        </div>
      </div>

      <div class="col-md-12">
        <br>
        <h3 class="cos-strong">Structure of MGSC</h3>
        <hr>
        <div class="cos-modal">
          <p>
            The MGSC consists of chair, secretary, honorary advisory member and steering committee member.
            The secretary and members will be nominated by the chair and elected through a meeting or online voting.
            There will be a board committee that will work with the chair and secretary to manage the group.
          </p>
          <br>
          <img class="img-responsive" src="../assets/static/img/Content/AY3_1.png">
          <br>
          <br>
          <p>
            All these positions are appointed for a period of four years. The appointment of the positions
            can be terminated mid-term for non-performance, or unsatisfactory performance, of duties.
          </p>
          <br />
        </div>
      </div>

      <div class="row " id="BoardMembers">
        <h3 class="cos-strong">Board Members</h3>
        <hr>
        <h4 class="cos-strong">Chair and Secretary</h4>
        <hr>
        <div class="container">
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/MinChen.jpg">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong>Prof. Min Chen</strong><br>
              <strong>Chair of MGSC of IGU</strong>
              <p>PhD, Professor <br>
                Nanjing Normal University, China<br>
                Links: <a href="http://schools.njnu.edu.cn/geog/person/min-chen" target="_blank" style="color: orange;">http://schools.njnu.edu.cn/geog/person/min-chen</a><br>
                Email: <a href="mailto:chenmin0902@163.com" style="color: orange;"> chenmin0902@163.com </a>
              </p>
            </div>
          </div>
        </div>

        <div class="container">
          <hr>
          <div class="row">
            <div class="col-md-3 col-xs-12 ">
              <img class="img-responsive" src="../assets/static/img/Members/Luo Wei.jpg" style="width: 173px;">
            </div>
            <div class="col-md-9 col-xs-12 m-center">
              <strong>Prof. Wei Luo</strong><br>
              <strong>Secretary General of MGSC of IGU</strong>
              <p>PhD, Assistant Professor <br>
                National University of Singapore, Singapore<br>
                Links: <a href="https://ap5.fas.nus.edu.sg/fass/geowl/" target="_blank" style="color: orange;">https://ap5.fas.nus.edu.sg/fass/geowl/</a><br>
                Email: <a href="geowl@nus.edu.sg" style="color: orange;"> geowl@nus.edu.sg </a>
              </p>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/mazaiyang.jpg">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong> Dr. Zaiyang Ma </strong><br>
              <strong>Secretary of MGSC of IGU</strong>
              <p>Ph.D.<br>
                Nanjing Normal University, China <br>
                Email: <a href="mailto:mzaiyang@outlook.com" style="color: orange;"> mzaiyang@outlook.com </a>
              </p>
            </div>
          </div>
        </div>

        <h4 class="cos-strong">Honorary Advisory Member (Alphabetically ordered by last name)</h4>
        <hr>
        <div class="container">
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/LUC ANSELIN.png">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong> Prof. Luc Anselin </strong><br>
              <p>PhD, Professor<br>
                University of Chicago, USA <br>
                Links: <a href="https://spatial.uchicago.edu/directory/luc-anselin-PhD" target="_blank" style="color: orange;">https://spatial.uchicago.edu/directory/luc-anselin-PhD</a><br>
                Email: <a href="mailto:anselin@uchicago.edu" style="color: orange;"> anselin@uchicago.edu </a>
              </p>
            </div>
          </div>
        </div>

        <div class="container">
          <hr>
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/Michael Batty.jpg">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong>Prof. Michael Batty </strong><br>
              <p>PhD, Professor<br>
                University College London, UK <br>
                Links: <a href="https://www.urbantransformations.ox.ac.uk/blog/2016/measuring-mobility-and-segregation-in-metropolitan-london-and-sao-paulo/" target="_blank" style="color: orange;">https://www.urbantransformations.ox.ac.uk/blog/</a><br>
                Email: <a href="mailto:m.batty@ucl.ac.uk" style="color: orange;"> m.batty@ucl.ac.uk </a>
              </p>
            </div>
          </div>
        </div>

        <div class="container">
          <hr>
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/Stewart Fotheringham.png">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong> Prof. Stewart Fotheringham </strong><br>

              <p>PhD, Professor<br>
                Arizona State University, USA <br>
                Links: <a href="https://isearch.asu.edu/profile/2372224" target="_blank" style="color: orange;">https://isearch.asu.edu/profile/2372224</a><br>
                Email: <a href="mailto:rbsgeo@hotmail.com" style="color: orange;"> sfotheri@asu.edu </a>
              </p>
            </div>
          </div>
        </div>

        <div class="container">
          <hr>
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/jakeman.jpg">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong> Prof. Tony Jakeman </strong><br>
              <p>PhD, Professor<br>
                Australian National University, Austrlia<br>
                Links: <a href="https://researchers.anu.edu.au/researchers/jakeman-aj" target="_blank" style="color: orange;">https://researchers.anu.edu.au/researchers/jakeman-aj</a><br>
                Email: <a href="mailto:tony.jakeman@anu.edu.au" style="color: orange;"> tony.jakeman@anu.edu.au </a>
              </p>
            </div>
          </div>
        </div>

        <div class="container">
          <hr>
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/Mei-Po Kwan.jpg">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong> Prof. Mei-Po Kwan </strong><br>
              <p>PhD, Professor<br>
                The Chinese University of Hong Kong, HKSAR, China <br>
                Links: <a href="http://www.meipokwan.org/" target="_blank" style="color: orange;">http://www.meipokwan.org/</a><br>
                Email: <a href="mailto:mpk654@gmail.com" style="color: orange;"> mpk654@gmail.com </a>
              </p>
            </div>
          </div>
        </div>

        <!-- <div class="container">
          <hr>
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/Li Xin.png">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong> Prof. Xin Li </strong><br>
              <p>PhD, Professor<br>
                Institute of Tibetan Plateau Research, Chinese Academy of Sciences, China <br>
                Links: <a href="http://sourcedb.itpcas.cas.cn/cn/expert/201806/t20180619_5027698.html" target="_blank" style="color: orange;">http://sourcedb.itpcas.cas.cn/cn/expert/201806/t20180619_5027698.html</a><br>
                Email: <a href="mailto:xinli@itpcas.ac.cn" style="color: orange;"> xinli@itpcas.ac.cn </a>
              </p>
            </div>
          </div>
        </div> -->

        <div class="container">
          <hr>
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/Guonian Lu.jpg">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong> Prof. Guonian Lu </strong><br>
              <p>PhD, Professor<br>
                Nanjing Normal University, China <br>
                Links: <a href="http://dky.njnu.edu.cn/info/1213/3877.htm" target="_blank" style="color: orange;">http://dky.njnu.edu.cn/info/1213/3877.htm</a><br>
                Email: <a href="mailto:gnlu@njnu.edu.cn" style="color: orange;"> gnlu@njnu.edu.cn </a>
              </p>
            </div>
          </div>
        </div>

        <!-- <div class="container">
          <hr>
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/RB Singh.jpg" style="filter: grayscale(1)">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong><u> Prof. R.B. SINGH </u></strong><br>
              <p>PhD, Professor<br>
                University of Delhi, India <br>
                Links: <a href="http://www.du.ac.in/du/uploads/Faculty%20Profiles/2017/Geography/May2017_Geography_R.B.Singh.pdf" target="_blank" style="color: orange;">http://www.du.ac.in/du/uploads/Faculty%20Profiles/2017/Geography/May2017_Geography_R.B.Singh.pdf</a><br>
                Email: <a href="mailto:rbsgeo@hotmail.com" style="color: orange;"> rbsgeo@hotmail.com </a>
              </p>
            </div>
          </div>
        </div> -->

        <div class="container">
          <hr>
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/JosefStrobl.jpg">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong> Prof. Josef Strobl </strong><br>
              <p>PhD, Professor<br>
                Universität Salzburg, Austria<br>
                Links: <a href="http://jstrobl.zgis.net/" target="_blank" style="color: orange;">http://jstrobl.zgis.net/</a><br>
                Email: <a href="mailto:josef.strobl@sbg.ac.at" style="color: orange;"> josef.strobl@sbg.ac.at </a>
              </p>
            </div>
          </div>
        </div>

        <div class="container">
          <hr>
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/WANG Jinfeng.jpg">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong> Prof. Jinfeng Wang </strong><br>
              <p>PhD, Professor<br>
                Institute of Geographic Sciences and Natural Resources Research, CAS, China <br>
                Links: <a href="http://sourcedb.igsnrr.cas.cn/yw/zjrck/200906/t20090626_1842448.html" target="_blank" style="color: orange;">http://sourcedb.itpcas.cas.cn/cn/expert/201806/t20180619_5027698.html</a><br>
                Email: <a href="mailto:wangjf@Lreis.ac.cn" style="color: orange;"> wangjf@Lreis.ac.cn </a>
              </p>
            </div>
          </div>
        </div>

        <h4 class="cos-strong">Steering Committee Members (Alphabetically ordered by last name)</h4>
        <hr>
        <div class="container">
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/TaoCheng.jpg">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong>Prof. Tao Cheng</strong><br>
              <p>PhD, Professor<br>
                University College London, UK<br>
                Links: <a href="https://www.ucl.ac.uk/work-at-ucl/our-community/meet-our-community/professor-tao-cheng" target="_blank" style="color: orange;">https://www.ucl.ac.uk/work-at-ucl/our-community/meet-our-community/professor-tao-cheng</a><br>
                Email: <a href="tao.cheng@ucl.ac.uk" style="color: orange;"> tao.cheng@ucl.ac.uk </a>
              </p>
            </div>
          </div>
        </div>

        <div class="container">
          <hr>
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/Christophe Claramunt.jpg">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong>Prof. Christophe Claramunt</strong><br>
              <p> PhD, Professor <br>
                French Naval Academy, France<br>
                Links: <a href="http://christophe.claramunt.free.fr/" target="_blank" style="color: orange;">http://christophe.claramunt.free.fr/</a><br>
                Email: <a href="mailto:christophe.claramunt@gmail.com " style="color: orange;"> christophe.claramunt@gmail.com </a>
              </p>
            </div>
          </div>
        </div>

        <div class="container">
          <hr>
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/Barry Croke.jpg">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong>Prof. Barry Croke</strong><br>
              <p>PhD, Professor <br>
                Australian National University, Australia<br>
                Links: <a href="https://researchers.anu.edu.au/researchers/croke-bfw" target="_blank" style="color: orange;">https://researchers.anu.edu.au/researchers/croke-bfw</a><br>
                Email: <a href="mailto:barry.croke@anu.edu.au" style="color: orange;"> barry.croke@anu.edu.au </a>
              </p>
            </div>
          </div>
        </div>

        <div class="container">
          <hr>
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/Andreas Koch.jpg">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong>Prof. Andreas Koch</strong><br>
              <p> PhD, Professor <br>
                Universität Salzburg, Austria<br>
                Links: <a href="https://www.uni-salzburg.at/index.php?id=22767&L=1" target="_blank" style="color: orange;">https://www.uni-salzburg.at/index.php?id=22767&L=1</a><br>
                Email: <a href="mailto:andreas.koch@sbg.ac.at" style="color: orange;"> andreas.koch@sbg.ac.at </a>
              </p>
            </div>
          </div>
        </div>

        <div class="container">
          <hr>
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/Manish Kumar.png">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong>Prof. Manish Kumar </strong><br>
              <p>PhD, Assistant Professor<br>
                Central University of Haryana, India <br>
                Links: <a href="http://www.cuh.ac.in/admin/uploads/files/manishks.pdf" target="_blank" style="color: orange;">http://www.cuh.ac.in/admin/uploads/files/manishks.pdf</a><br>
                Email: <a href="mailto:manish.ks@cuh.ac.in" style="color: orange;"> manish.ks@cuh.ac.in </a><br>
                Email: <a href="mailto:manish.ks1@gmail.com" style="color: orange;"> manish.ks1@gmail.com </a>
              </p>
            </div>
          </div>
        </div>

        <div class="container">
          <hr>
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/Erik Nixdorf.jpg">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong>Dr. Erik Nixdorf </strong><br>
              <p>PhD<br>
                Federal Institute for Geosciences and Natural Resources, Germany <br>
                Links: <a href="https://www.bgr.bund.de/EN/Themen/FEZB/FEZB_node_en.html" target="_blank" style="color: orange;">https://www.bgr.bund.de/EN/Themen/FEZB/FEZB_node_en.html</a><br>
                Email: <a href="mailto:erik.nixdorf@bgr.de" style="color: orange;"> erik.nixdorf@bgr.de </a>
              </p>
            </div>
          </div>
        </div>

        <div class="container">
          <hr>
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/Antonio Pez.jpg">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong>Prof. Antonio Páez</strong><br>

              <p> PhD, Professor <br>
                McMaster Uniersity, Canada<br>
                Links: <a href="https://www.science.mcmaster.ca/ees/component/comprofiler/userprofile/paezha.html?itemid=351" target="_blank" style="color: orange;">https://www.science.mcmaster.ca/ees/component/comprofiler/userprofile/paezha.html?itemid=351</a><br>
                Email: <a href="paezha@mcmaster.ca" style="color: orange;"> paezha@mcmaster.ca </a>
              </p>
            </div>
          </div>
        </div>

        <div class="container">
          <hr>
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/Karel Stane.jpg">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong>Prof. Karel Staněk </strong><br>
              <p>PhD, Assistant Professor<br>
                Masaryk University, Czech Republic <br>
                Links: <a href="https://www.muni.cz/en/people/1944-karel-stanek" target="_blank" style="color: orange;">https://www.muni.cz/en/people/1944-karel-stanek</a><br>
                Email: <a href="mailto:erik.nixdorf@ufz.de" style="color: orange;"> karst@geogr.muni.cz </a>
              </p>
            </div>
          </div>
        </div>

        <div class="container">
          <hr>

          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/Paul Torrens.png">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong>Prof. Paul Torrens</strong><br>
              <p>PhD, Professor<br>
                New York University, USA<br>
                Links: <a href="https://cusp.nyu.edu/profiles/paul-m-torrens/" target="_blank" style="color: orange;">https://cusp.nyu.edu/profiles/paul-m-torrens/</a><br>
                Email: <a href="mailto:torrens@geosimulation.com" style="color: orange;"> torrens@geosimulation.com </a>
              </p>
            </div>
          </div>
        </div>

        <div class="container">
          <hr>

          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/Rumiana Vatseva.jpg">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong>Prof. Rumiana Vatseva</strong><br>
              <p>PhD, Professor<br>
                Bulgarian Academy of Sciences, Bulgaria<br>
                Links: <a href="http://www.niggg.bas.bg/wp-content/uploads/2018/11/geography/gis/en/R_Vatseva.html" target="_blank" style="color: orange;">http://www.niggg.bas.bg/wp-content/uploads/2018/11/geography/gis/en/R_Vatseva.html</a><br>
                Email: <a href="mailto:rvatseva@gmail.com" style="color: orange;"> rvatseva@gmail.com </a>
              </p>
            </div>
          </div>
        </div>

        <!-- <h4 class="cos-strong">Office Assistant</h4>
        <hr>
        <div class="container">
          <div class="row">
            <div class="col-md-3 col-xs-12">
              <img class="img-responsive" src="../assets/static/img/Members/mazaiyang.jpg">
            </div>
            <div class="col-md-9 col-xs-12">
              <strong> Dr. Zaiyang Ma </strong><br>
              <p>Ph.D.<br>
                Nanjing Normal University, China <br>
                Email: <a href="mailto:mzaiyang@outlook.com" style="color: orange;"> mzaiyang@outlook.com </a>
              </p>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <el-backtop style="visibility-height:1400"></el-backtop>
  </div>
</template>

<script>
export default {
  name: 'AboutUs'
}
</script>

<style lang="less" scoped>
#aboutUs {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  text-align: justify;
}

hr {
  border: 0.1px solid;
  border-color: #eeeeee;
}

.img-responsive {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: auto;
  max-width: 100%;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

h4 {
  color: #1a80b6;
}

#mission {
  color: #1a80b6;
  line-height: 1.6rem;
}

li {
  line-height: 2.2rem;
}

.row {
  align-items: center;
  margin: 2.2rem 0;
  line-height: 2rem;
}

p {
  margin-bottom: 0px;
}

a {
  text-decoration: none;
}
</style>
