<template>
  <div id="Awards">
    <div id="Awards2">
      <div>
        <h3 class="cos-strong">Best Paper Awards</h3>
        <hr>
        <div>
          <p>The Best Paper Award (BPA) is presented to the individual(s) judged by an awards committee to have written the best paper appearing in the annual conference proceedings.<br></p>
          <p>Two BPAs will be awarded at each annual conference. Notice of BPA application will be announced along with the Annual Conference Notice.</p>
        </div>
      </div>

      <br>

      <div>
        <h3 class="cos-strong">Excellent Young Scholar Award</h3>
        <hr>
        <div>
          <p>The Young Scholar Award (YSA) is presented to the individual(s) judged by an awards committee recognize his or her outstanding scientific achievements and contributions. Applicants needs to hold a PhD degree, and within seven years of graduation.<br></p>
          <p>Two YSAs will be awarded each year.<br></p>
          <p>Details regarding requirements and documents for application will be announced soon on this website.</p>
        </div>
      </div>

      <br>

      <div>
        <h3 class="cos-strong">Outstanding Achievement Award</h3>
        <hr>
        <div>
          <p>The Outstanding Achievement Award is conferred by the commission on members of the commission who have given long and meritorious service to the commission or for particularly meritorious academic contribution of a shorter duration.</p>
          <p>One OAA will be awarded every year.<br></p>
          <p>Details regarding requirements and documents for application will be announced soon on this website.</p>
        </div>
      </div>

      <br>

      <div>
        <h3 class="cos-strong">Distinguished Service Award</h3>
        <hr>
        <p>The Distinguished Service Award (DSA) is conferred by
          the commission on members of the commission who have given long
          and meritorious service to the commission or for particularly meritorious service of a shorter duration.</p>
        <p>One DSA will be awarded every year.</p>
        <p>Details regarding requirements and documents for application will be announced soon on this website.</p>
      </div>
      <div style="margin-top:10px">
        <a href="http://39.98.210.144:80/data/IGU MGSC Award Nomination Letter-2024.docx">IGU MGSC Award Nomination Letter-2024</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Awards'
}
</script>

<style lang="less" scoped>
#Awards {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  text-align: justify;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

hr {
  border: 0.1px solid;
  border-color: #eeeeee;
}
</style>
