<template>
  <div id="Home">
    <div>
      <h3>Vision of MGSC</h3>
      <hr />
      <div id="vision">
        The vision of Modeling Geographical Systems Commission (MGSC) of
        International Geographical Union (IGU) is to share an interest in
        developing the theories, dynamics, simulation and decision-making of
        geographical systems using statistical and mathematical approaches, and
        further to enhance research on modeling and simulation methods and other
        technological advances for geographic pattern analysis and process
        exploration. <br /><br />
      </div>
    </div>

    <div>
      <h3>Latest News</h3>
      <hr />
      <div>
        <router-link to="/Events/2024/2">
          <strong class="home-h"
            ><a id="Call-for-Nominations"
              >Call for Nominations - IGU Outstanding Achievement Award and
              Excellent Young Scholar Award in Modeling Geographical Systems
              (2024) </a
            ><br
          /></strong>
        </router-link>
        <p>
          The IGU Outstanding Achievement Award and Excellent Young Scholar
          Award in Modeling Geographical Systems (OAA 2024 and YSA 2024) are
          receiving nominations to recognize researchers for long and
          meritorious contributions to the community of geographic modeling
          research or young researchers for meritorious academic contributions
          in their early career. An awards committee will be organized by IGU
          through its Modeling Geographical Systems Commission (IGU MGSC), and
          it will solicit and evaluate the nominees and presents the awards to
          the individuals in light of their excellent scientific achievements
          and contributions.
          <br />
        </p>
        <p>
          The abstract follows:
          <router-link to="/Events/2024/2"> (more…) </router-link>
        </p>
      </div>
      <hr style="border-bottom: 3px double grey" />
      <div>
        <router-link to="/Events/2024/1">
          <strong class="home-h"
            ><a id="Call-for-Nominations"
              >The 3rd International Workshop on Geographic Modeling and
              Simulation: Modeling and Simulation for A Sustainable World</a
            ><br
          /></strong>
        </router-link>
        <p>
          In a sustainable world, it is imperative to meet people's needs
          without causing chronic harm to the environment or compromising the
          ability of future generations. Achieving sustainability requires
          extensive research encompassing various theories and approaches from
          diverse domains. This research requires not only real-time observation
          but also a profound understanding of global evolution, enabling
          long-term analysis and forecasting. The complexities of understanding
          and forecasting evolutionary processes necessitate geographic modeling
          and simulation, which have emerged as potent tools for addressing
          critical issues such as climate change and sustainable resource use.
          Research in this field spans disciplines including geomorphology,
          hydrology, soil science, biology, climate science, and social science,
          all focused on unraveling the interactive mechanisms of surface
          processes.
          <br />
        </p>
        <p>
          The abstract follows:
          <router-link to="/Events/2024/1"> (more…) </router-link>
        </p>
      </div>
      <hr style="border-bottom: 3px double grey" />
      <div>
        <router-link to="/Events/2023/3">
          <strong class="home-h"
            ><a id="Call-for-Nominations"
              >Call for abstracts | IGC 2024 Modeling and Simulating
              Geographical Systems in the Big Data Era session </a
            ><br
          /></strong>
        </router-link>
        <p>
          The 35th International Geographical Congress (IGC 2024) will be held
          in Dublin, Ireland from 24 to 30 August 2024. Around the theme
          "Celebrating a World of Difference" of the congress, the Modeling
          Geographical Systems Commission (MGSC) of IGU will organize a
          session–Modeling and Simulating Geographical Systems in the Big Data
          Era–to share the research achievements, discuss common challenges and
          opportunities, and connect with colleagues across the world in this
          field.
          <br />
        </p>
        <p>
          The abstract follows:
          <router-link to="/Events/2023/3"> (more…) </router-link>
        </p>
      </div>
      <hr style="border-bottom: 3px double grey" />
      <div>
        <router-link to="/Events/2023/2">
          <strong class="home-h"
            ><a id="Call-for-Nominations"
              >Announcement of IGU MGSC Awards 2023 </a
            ><br
          /></strong>
        </router-link>
        <p>
          After careful consideration by the awards committee, the IGU MGSC
          Awards 2023 were announced.These awards were organized and judged by
          the Modeling Geographical Systems Commission of IGU. They aim to
          recognize outstanding academic contributions in the field of
          geographic modeling research and promote innovation and development in
          modeling geographic systems.
          <br />
        </p>
        <p>
          The abstract follows:
          <router-link to="/Events/2023/2"> (more…) </router-link>
        </p>
      </div>
      <hr style="border-bottom: 3px double grey" />
      <div>
        <router-link to="/Events/2023/1">
          <strong class="home-h"
            ><a id="Call-for-Nominations"
              >Minutes of Modeling Geographical Systems Commission (MGSC) board
              meeting </a
            ><br
          /></strong>
        </router-link>
        <p>
          The board committee of Modeling Geographical Systems Commission of IGU
          (IGU MGSC) held the 2022 Annual Meeting of Board Members. There are 14
          participants in total. First of all, Prof. Wei Luo and Prof. Min Chen
          reported the progress in member registration and events in 2022, and
          discussed future activities. Prof. Min Chen also highlighted the
          recent publications on position papers from MGSC committee and
          encouraged more similar work to lead the future directions of
          geographical modeling and simulation field. After that, all of the
          committee provided very useful feedback and comments for what we have
          done in the past year and what we could do in the future. The meeting
          photo of all attendees is taken below.
          <br />
        </p>
        <p>
          The abstract follows:
          <router-link to="/Events/2023/1"> (more…) </router-link>
        </p>
      </div>
      <hr style="border-bottom: 3px double grey" />
      <div>
        <router-link to="/Events/2022/5">
          <strong class="home-h"
            ><a id="Call-for-Nominations"
              >2022 Annual Academic Conference of the Geographic Modeling and
              Geographic Information Analysis Committee of the Chinese
              Geographical Society </a
            ><br
          /></strong>
        </router-link>
        <p>
          The 2022 Annual Academic Conference of the Geographic Modeling and
          Geographic Information Analysis Committee was held in Nanjing,
          Jiangsu, China, from September 17 to September 18, 2022. The theme of
          the conference is "Geographic Information Modeling and Simulation in
          the New Era of Geography - Exploration and Practice", and the
          conference aims to explore new opportunities, new positions, and new
          paradigms for the development of geographic information modeling and
          geographic information analysis in the interdisciplinary context.
          <br />
        </p>
        <p>
          The abstract follows:
          <router-link to="/Events/2022/5"> (more…) </router-link>
        </p>
      </div>
      <hr style="border-bottom: 3px double grey" />

      <div>
        <router-link to="/Events/2022/3">
          <strong class="home-h"
            ><a id="Call-for-Nominations"
              >The second training course on Chinese geographical analysis
              models</a
            ><br
          /></strong>
        </router-link>
        <p>
          The 2nd training course on Chinese geographic analysis models was
          successfully held from July 30th to August 6th, 2022. Similar to the
          course last year, it was mainly organized by Nanjing Normal
          University, the Geographical Society of China (GSC), China Society of
          Natural Resources (CSNR), China Association for Geospatial Information
          Society (CAGIS), Chinese National Committee of International Society
          for Digital Earth (ISDE). Our Modeling Geographical Systems Commission
          of the International Geographical Union (IGU) was also one of the
          co-organizers to support the training course.
          <br />
        </p>
        <p>
          The abstract follows:
          <router-link to="/Events/2022/3"> (more…) </router-link>
        </p>
      </div>
      <hr style="border-bottom: 3px double grey" />

      <!-- <div>
        <router-link to="/Events/2022/4">
          <strong class="home-h"><a id="Call-for-Nominations">The session of Geographic information modelling and simulation in the era of big data in IGU Paris 2022</a><br></strong>
        </router-link>
        <p> The centennial congress of the International Geographical Union (IGU) was held in Paris from June 18 to 22, 2022. In this congress, Modeling Geographical Systems Commission (MGSC) of IGU organized a virtual session–Geographic information modelling and simulation in the era of big data–to promote idea exchange and achievement sharing in this field. 7 experts and scholars attended this session and gave presentations. Several topics, including Substantial Development, Big Data and Climate Change, Plant Nitrogen Content Estimation, Land Value Uplift, Model Sharing and Reuse, Rooftop Solar Potential Evaluation, and Geographic Simulation Reproduction, were discussed by attendees.
          <br>
        </p>
        <p>The abstract follows:
          <router-link to="/Events/2022/4">
            (more…)
          </router-link>
        </p>
      </div>
      <hr style="border-bottom:3px double grey;"> -->
      <!-- 
      <div>
        <router-link to="/Events/2022/2">
          <strong class="home-h"><a id="Call-for-Nominations">Announcement of IGU MGSC Awards 2022</a><br></strong>
        </router-link>
        <p>After careful consideration and selection by the awards committee, the IGU MGSC Awards 2022 were announced. These awards were established by the Modeling Geographical Systems Commission of IGU. They aim to recognize outstanding academic contributions in the field of geographic modeling research and promote innovation and development in modeling geographic systems. This time, one winner of the Outstanding Achievement Award (OAA) and two winners of the Excellent Young Scholar Award (YSA) were selected.
          <br>
        </p>
        <p>The abstract follows:
          <router-link to="/Events/2022/2">
            (more…)
          </router-link>
        </p>
      </div>
      <hr style="border-bottom:3px double grey;">

      <div>
        <router-link to="/Events/2022/1">
          <strong class="home-h"><a id="Call-for-Nominations">Call for Nominations - IGU Outstanding Achievement Award and Excellent Young Scholar Award in Modeling Geographical Systems (2022) </a><br></strong>
        </router-link>
        <p>The IGU Outstanding Achievement Award and Excellent Young Scholar Award in Modeling Geographical Systems (OAA 2022 and YSA 2022) are receiving nominations to recognize researchers for long and meritorious contributions to the community of geographic modeling research or young researchers for meritorious academic contributions in their early career. An awards committee will be organized by IGU through its Modeling Geographical Systems Commission (IGU MGSC), and it will solicit and evaluate the nominees and presents the awards to the individuals in light of their excellent scientific achievements and contributions.<br></p>
        <p>The abstract follows:
          <router-link to="/Events/2022/1">
            (more…)
          </router-link>
        </p>
      </div>
      <hr style="border-bottom:3px double grey;"> -->
      <div>
        <router-link to="/Events/2021/1">
          <strong class="home-h"
            ><a id="Call-for-Abstracts"
              >Call for Abstracts | The session of Geographic information
              modelling and simulation in the era of big data in IGU Paris
              2022</a
            ><br
          /></strong>
        </router-link>
        <small class="home-date">June 18 to 22, 2022<br /></small>
        <p>
          The centennial congress of the International Geographical Union (IGU)
          will be held in Paris from June 18 to 22, 2022. Around the theme "Time
          for Geographers" of the congress, the Modeling Geographical Systems
          Commission (MGSC) of IGU will organize a virtual session–Geographic \
          information modelling and simulation in the era of big data–to promote
          idea exchange and achievement sharing in this field.<br />
        </p>

        <p>
          The abstract follows:
          <router-link to="/Events/2021/1"> (more…) </router-link>
        </p>
      </div>

      <hr style="border-bottom: 3px double grey" />

      <div>
        <strong class="home-h"
          ><a
            href="http://175.27.137.60:8083/data/a06c7fe8-f843-418f-b6cd-560a8d1dd704"
            target="_blank"
            >The 2nd International Geography and Sustainability Workshop
            (online)</a
          ><br
        /></strong>
        <small class="home-date">Nov 23th-24th, 2021<br /></small>
        <p>
          The 2021 International Geography and Sustainability Workshop (online)
          will be held from November 23 to 24. This workshop brings together
          researchers around the world, in collaboration with IGU commissions,
          to identify common challenges and discuss solutions to promote
          sustainable development through an integrated geographic perspective.
          It will present the latest research progress on geography and
          sustainability and is expected to provide a valuable contribution to
          transforming our world for sustainable development. Specific
          objectives are: to identify critical research areas on geography and
          sustainability; and present the geographical case studies on
          transforming our world for sustainable development.<br />
        </p>
        <strong>
          <p>Welcome to join us!</p>
        </strong>
        <p>
          The abstract follows:
          <a
            href="http://175.27.137.60:8083/data/a06c7fe8-f843-418f-b6cd-560a8d1dd704"
            target="_blank"
            >(more…)</a
          >
        </p>
      </div>

      <hr style="border-bottom: 3px double grey" />

      <!-- <div>
        <router-link to="/Events/2021/6">
          <strong class="home-h"><a id="#Minutes-of-the-second">Minutes of the second board meeting of Modeling Geographical Systems Commission (MGSC)</a><br></strong>
        </router-link>
        <small class="home-date">Sep 20th, 2021<br></small>
        <p>Prof. Min Chen in the meeting introduced the events and news that happened this year, followed by discussing with the board members about the future activities. The major topics and discussion are shown below:<br></p>
        <ul>
          <li>The progress in member registration.</li>
          <li>Review of important events</li>
          <li>Special issues</li>
          <li>Calling for new members</li>
          <li>Collecting news on geographic modeling and simulation and spreading to our colleagues via our website and mail list</li>
          <li>Hosting or organizing conferences, workshops, and webinar</li>
          <li>Four awards by committee meeting: Best Paper, Excellent Young Scholar, Distinguished Scholar and Distinguished Service.</li>
        </ul>
        <p>The abstract follows: <a class="home-ha" id="#Minutes-of-the-second">
            <router-link to="/Events/2021/6">
              (more…)
            </router-link>
          </a></p>
      </div>

      <hr style="border-bottom:3px double grey;"> -->

      <!-- <div>
        <router-link to="/Events/2021/5">
          <strong class="home-h"><a id="#IGU-19th-International">IGU 19th International Conference on Spatial Data Handling and Geographic Intelligence</a><br></strong>
        </router-link>
        <small class="home-date">Aug 13th-14th, 2021<br></small>
        <p>During August 13-14, 2021, the 19th International Conference on Spatial Data Handling and Geographic Intelligence was successfully held. The IGU Comission on Geographical Information Science and Commission on Modeling Geographical Systems co-organized this conference.<br></p>
        <p>The abstract follows: <a id="#IGU-19th-International">
            <router-link to="/Events/2021/5">
              (more…)
            </router-link>
          </a>
        </p>
      </div> -->
      <!-- <hr style="border-bottom:3px double grey;"> -->

      <!-- <div>
        <router-link to="Events/2021/4">
          <strong class="home-h"><a id="#The-first-training-course">The first training course on Chinese geographical analysis models</a><br></strong>
        </router-link>
        <small class="home-date">Aug 9th-16th, 2021<br></small>
        <p>The 1st training session on Chinese geographic analysis models was successfully held during August 9th-16th, 2021. This training session was mainly organized by Nanjing Normal University, the Geographical Society of China (GSC), China Society of Natural Resources (CSNR), China Association for Geospatial Information Society (CAGIS), Chinese National Committee of International Society for Digital Earth (ISDE).<br></p>
        <p>The abstract follows: <a id="#The-first-training-course">
            <router-link to="Events/2021/4">
              (more…)
            </router-link>
          </a></p>
      </div> -->
    </div>
    <el-backtop style="visibility-height: 1400"></el-backtop>
  </div>
</template>

<script>
export default {
  name: 'Home'
};
</script>

<style lang="less" scoped>
#Home {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  text-align: justify;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

hr {
  border: 0.1px solid;
  border-color: #eeeeee;
}

#vision {
  line-height: 2.1rem;
}

.home-h {
  font-size: larger;
  font-weight: bolder;
}

.home-date {
  font-weight: bold;
  color: grey;
}
a {
  text-decoration: none;
  font-size: 1.2rem;
  color: #1a80b6;
}
</style>
