<template>
  <div id="_2021_3">
    <div id="Minutes-of-the-first" class="cos-modal">
      <h3><strong>Minutes of the first board meeting of Modeling
          Geographical Systems Commission (MGSC)</strong></h3>
      <small class="home-date">Jan 27, 2021 <br></small>
      <hr>
      <p>
        <b>Time: </b>7:00PM, Jan 27, 2021 (Beijing Time)<br>
        <b>Venue: </b>Online via Zoom<br>
        <b>Attendees(Alphabetically ordered by last name): </b>
      <ol>
        <li>Prof. Michael Batty</li>
        <li>Prof. Min Chen</li>
        <li>Prof. Tao Cheng</li>
        <li>Prof. Christophe Claramunt</li>
        <li>Prof. Barry Croke</li>
        <li>Prof. Andreas Koch</li>
        <li>Dr. Manish Kumar</li>
        <li>Prof. Mei-Po Kwan</li>
        <li>Prof. Xin Li</li>
        <li>Dr. Xintao Liu</li>
        <li>Prof. Antonio Paez </li>
        <li>Dr. Karel Staněk</li>
        <li>Prof. Josef Strobl</li>
        <li>Prof. Jinfeng Wang</li>
      </ol>
      Prof. Min Chen kicked off the meeting by introducing himself, followed
      by the board members introducing themselves. The major topics and
      discussion are shown below:
      <ol>
        <li>
          The website of MGSC has been launched at
          http://www.igu-geomodeling.com. Prof. Min Chen went through the
          website and introduced the main functions.
        </li>

        <li>
          Four awards by committee meeting: Best Paper, Excellent Young
          Scholar, Distinguished Scholar and Distinguished Service.
        </li>

        <li>
          Membership of MGSC: renew annually, free of charge, list on
          website.
        </li>

        <li>
          Objectives to achieve for four years.
        </li>

        <li>
          Events (workshop, conference, etc.) to be organized in 2021.
        </li>

        <li>

          Regular meeting for every half year.

          Reasonable (Michael Batty, Mei-Po Kwan). To be scheduled in due
          course.
        </li>

        <li>
          Other related stuff?<br>
          N/A
        </li>

        <li>
          Online photo of all attendees was taken.
          <div>
            <img src="../../../assets/static/img/Events//Minutes-of-the-first.png" style="width: 100%">
          </div>
        </li>
      </ol>

      </p>

      <p></p>
    </div>
  </div>
</template>

<script>
export default {
  name: '_2021_3'
}
</script>

<style>
#_2021_3 {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  line-height: 2rem;
  text-align: justify;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

.home-date {
  font-weight: bold;
  color: grey;
}

li {
  line-height: 2.2rem;
}

p {
  line-height: 2.2rem;
}
</style>