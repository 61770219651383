<template>
  <div id="_2021_1">
    <div id="Call-for-Abstracts" class="cos-modal">
      <h3><strong>Call for Abstracts | The session of Geographic information modelling and simulation in the era of big data in IGU Paris 2022</strong></h3>
      <small class="home-date">June 18 to 22, 2022<br></small>
      <hr>
      <p> &nbsp;&nbsp;&nbsp;&nbsp;The centennial congress of the International Geographical Union (IGU) will be held in Paris from June 18 to 22, 2022.
        Around the theme "Time for Geographers" of the congress, the Modeling Geographical Systems Commission (MGSC) of IGU will organize a virtual
        session–Geographic information modelling and simulation in the era of big data–to promote idea exchange and achievement sharing in this field.<br></p>

      <div style="margin-top: 2%;margin-bottom: 2%;">
        <img src="../../../assets/static/img/Events/Call-for-Abstracts.png" style="width: 100%">
      </div>
      <h3>Session description</h3>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;In the age of Internet, big data has become an important resource of human society.
        It is growing exponentially and rapidly affecting the development and transformation of human society.
        There are new opportunities that have also been taking place in the development of geography.
        In this context, the stage of "measurement revolution" and the application of geographic information system
        in geographic models and geographic information analyses have been past, and now they are stepping into the era of big data.<br>

        &nbsp;&nbsp;&nbsp;&nbsp;On one hand, we need to find a way to invert the process of changes in the earth’s surface and establish a
        geoscience information map through the spatiotemporal digital construction based on computational science. On the
        other hand, with the support of big data and artificial intelligence, we need to explore the complex laws of earth
        surface changes relying on the professional knowledge of geosciences, through which we can discover new geoscience
        knowledge and realize holographic intelligent planning and prediction of the future. For example, there has been COVID-19
        spreading globally since early 2020 until nowadays. To tackle the pandemic, spatiotemporal big data analysis and geographic
        modelling have been put forward with higher requirements in terms of geographic detection of epidemic risk, spatiotemporal
        diffusion laws, dissemination of early warnings, medical resource analysis, online public opinion monitoring, etc..<br>

        &nbsp;&nbsp;&nbsp;&nbsp;This session aims to discuss and explore related theories,
        methods and potential practices, facilitating communication between experts from different domains, encouraging extensive discussion regarding the
        potential directions of the field, and promoting further research for a bright future.
      </p>
      <h3>Abstract submission</h3>
      <ul>
        <li>Abstracts should be submitted in English.</li>
        <li>Please follow the instructions below for writing your abstract in the submission form:<br>
          1. Title: maximum of 255 characters (spaces included);<br>
          2. Resume: Text should be a maximum of 2000 characters (spaces included);<br>
          3. Keywords: maximum of 5.<br>
          4. References: maximum of 5.<br>

        </li>
        <li>Website: <a target="_blank" href="https://www.ugiparis2022.org/en/abstracts-submission/29">https://www.ugiparis2022.org/en/abstracts-submission/29</a></li>
      </ul>
      <h5>Now, the call for abstracts is open! You are invited to submit your abstracts for oral presentations.</h5>
      <strong>Abstract submission</strong>
      <p>The deadline for the abstract proposals is January 11th, 2022.<br>
        The announcement of the accepted abstracts is expected by January 21st, 2022.<br>

        For other information about the centennial congress, please refer to:

        <a href="https://www.ugiparis2022.org/en/scientificinformation/1" target="_blank">https://www.ugiparis2022.org/en/scientificinformation/1</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: '_2021_1'
}
</script>

<style lang="less" scoped>
#_2021_1 {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  line-height: 2rem;
  text-align: justify;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

a {
  text-decoration: none;
}

.home-date {
  font-weight: bold;
  color: grey;
}
</style>