<template>
  <div id="_2022_5">
    <div id="Call-for-Nominations">
      <h3>
        <strong class="home-h"
          >The 3rd International Workshop on Geographic Modeling and Simulation:
          Modeling and Simulation for A Sustainable World
        </strong>
      </h3>
      <hr />
      <p>
        In a sustainable world, it is imperative to meet people's needs without
        causing chronic harm to the environment or compromising the ability of
        future generations. Achieving sustainability requires extensive research
        encompassing various theories and approaches from diverse domains. This
        research requires not only real-time observation but also a profound
        understanding of global evolution, enabling long-term analysis and
        forecasting. The complexities of understanding and forecasting
        evolutionary processes necessitate geographic modeling and simulation,
        which have emerged as potent tools for addressing critical issues such
        as climate change and sustainable resource use. Research in this field
        spans disciplines including geomorphology, hydrology, soil science,
        biology, climate science, and social science, all focused on unraveling
        the interactive mechanisms of surface processes. Contemporary
        geographical modeling and simulation encounter significant challenges,
        including grasping the intricacies of the geographic world, integrating
        simulations across diverse domains, and fostering participatory modeling
        with multiple stakeholders. However, existing theories and approaches
        struggle to adequately address these challenges. In response, a
        promising solution for tackling complex geographical problems involves
        engaging dispersed multidisciplinary experts and resources for
        collaborative problem-solving. This approach facilitates experimentation
        and the pursuit of optimal solutions through joint research efforts,
        proving not only feasible but also highly effective in advancing
        geographic research.
      </p>
      <p>
        To further support this collaborative approach, there is a pressing need
        for an open geographical modeling and simulation framework. Such a
        framework would serve as a catalyst, attracting more scholars and
        encouraging their collective efforts to address geographic challenges.
        This 3rd International Workshop on Geographic Modeling and Simulation is
        meticulously designed to explore pertinent theories, approaches, and
        potential applications, while fostering communication among experts from
        diverse domains. Its primary objectives include stimulating extensive
        discussions regarding the potential directions of the field and
        promoting further research, thus paving the way for a promising future
        in collaborative geographic modeling and simulation.
      </p>
      <p>
        The workshop will be hold in Oct 25-28, 2024 in Nanjing, China. Modeling
        Geographical Systems Commission of IGU is one of the organizers. More
        details please see:
        <a href="https://geomodeling.njnu.edu.cn/iwgms2024/"
          >https://geomodeling.njnu.edu.cn/iwgms2024/</a
        >
        .
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: '_2024_1'
};
</script>
<style scoped lang="less">
#_2022_5 {
  height: fit-content;
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  line-height: 2rem;
  text-align: justify;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

h4 {
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
}

.home-date {
  font-weight: bold;
  color: grey;
}

a {
  text-decoration: none;
}

.pic {
  // display: table-cell;
  text-align: center;
  height: 128px;
  width: 128px;
  overflow: hidden;
}
.flex {
  display: flex;
  // justify-content: center;
}
</style>
