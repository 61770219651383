<template>
  <div id="_2022_3">
    <div id="Announcement-IGU-MGSC-Awards-2022">
      <h3><strong class="home-h">The second training course on Chinese geographical analysis models</strong></h3>
      <hr>
      <p>The 2nd training course on Chinese geographic analysis models was successfully held from July 30th to August 6th, 2022. Similar to the course last year, it was mainly organized by Nanjing Normal University, the Geographical Society of China (GSC), China Society of Natural Resources (CSNR), China Association for Geospatial Information Society (CAGIS), Chinese National Committee of International Society for Digital Earth (ISDE). Our Modeling Geographical Systems Commission of the International Geographical Union (IGU) was also one of the co-organizers to support the training course.</p>

      <div class="pic">
        <img style="max-width: 80%;" src="../../../assets/static/img/Events/The-second-training-course.png"><br><br>
      </div>

      <p>This training course focused on the topic “Spatiotemporal modeling and analysis”. Four models are introduced in detail, including the GeoDetector model developed by the team of Prof. Jingfeng Wang (Institute of Geographic Sciences and Natural Resources Research, CAS), the GeoSOS model developed by the team of Prof. Xia Li (East China Normal University), the HASM model developed by the team of Prof. Tianxiang Yue(Institute of Geographic Sciences and Natural Resources Research, CAS), and the GWmodelS model developed by the teams of Prof. Bingbing Lu (Wuhan University) and Prof. Guanpeng Dong(Henan University). There were 2044 students from 436 universities, institutes, and companies in 10 countries attending the online training course.</p>
      <p>The success of this training course can promote the construction of the Chinese geographic model ecosystem and the open and sustainable geographic model community. It can also facilitate model reusing, knowledge sharing, and collaborative geographic research.</p>

    </div>
  </div>
</template>

<script>
export default {
  name: '_2022_3'
}
</script>

<style lang="less" scoped>
#_2022_3 {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  line-height: 2rem;
  text-align: justify;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

h5 {
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
  color: #1a80b6;
}

.home-date {
  font-weight: bold;
  color: grey;
}

a {
  text-decoration: none;
}

.pic {
  width: 100rem;
  display: table-cell;
  text-align: center;
}
</style>