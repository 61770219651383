<template>
  <div id="_2022_1">
    <div id="Call-for-Nominations">
      <h3><strong class="home-h">Call for Nominations - IGU Outstanding Achievement
          Award and Excellent Young Scholar Award in Modeling Geographical Systems
          (2022)
        </strong></h3>
      <hr>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;The <strong>IGU Outstanding Achievement Award and Excellent Young Scholar
          Award in Modeling Geographical Systems (OAA 2022 and YSA
          2022)</strong> are receiving nominations to recognize researchers
        for long and meritorious contributions to the community of geographic
        modeling research or young researchers for meritorious academic
        contributions in their early career. An awards committee will be
        organized by IGU through its Modeling Geographical Systems Commission
        (IGU MGSC), and it will solicit and evaluate the nominees and presents
        the awards to the individuals in light of their excellent scientific
        achievements and contributions.
      </p>
      <h4><strong>Eligibility</strong></h4>
      <li>Nominees for the OAA 2022 are expected to have outstanding research
        contributions to geographic modeling and relevant fields.</li>
      <li>Nominees for the YSA 2022 should have received a doctoral degree in the
        last seven years (no earlier than 2015) and have significant research
        contributions to geographic modeling and relevant fields.</li>
      <li>Nominees for the YSA 2022 also should be IGU MGSC members.</li>
      <h4><strong>Submission Instructions</strong></h4>
      &nbsp;&nbsp;&nbsp;&nbsp;To nominate an award for OAA 2022, please submit the following documents as
      PDF files:
      <li><strong>Nomination Letter: </strong> A personal statement is needed to
        describe the nominee’s contributions and achievements. The statement
        should be no more than 500 words.</li>
      <li><strong>Curriculum Vitae or Resume: </strong>The latest curriculum vitae
        or resume of nominees.</li>
      <li><strong>Certificate of Academic Degree: </strong>The certificate can
        prove the eligibility of nominees.</li>
      <li><strong>Two Recommendation Letter: </strong>The recommender must have a
        formal professional role that allows them to assess the nominee’s
        achievements, current academic status and progress. The recommendation
        letter should be signed by the recommenders. Once prepared, the letter
        should be converted to a PDF format.</li>
      &nbsp;&nbsp;The Nomination Letter Template can be found under the “Awards” page of the
      official website of IGU MGSC (http://www.igu-geomodeling.com/).<br>
      &nbsp;&nbsp;Nominators or nominees need to merge the mentioned documents into a single
      PDF file and submit it to Zaiyang Ma (<a href="mailto:mzaiyang@outlook.com">mzaiyang@outlook.com</a> or <a href="mailto:zy_ma327@126.com">zy_ma327@126.com</a> ), and include “IGU
      MGSC Outstanding Achievement Award” or “IGU MGSC Excellent Young Scholar
      Award” in the email subject line.<strong> (Self-nominations are not
        accepted.)</strong><br>

      <h4><strong>Important date</strong></h4>
      &nbsp;&nbsp;&nbsp;&nbsp;The tentative dates for submission and notifications are as follows.<br>
      <li>Deadline for submission: Jun 30, 2022</li>
      <li>Deadline for notification to the awardee: Jul 31, 2022</li>

      <h4><strong>Contact</strong></h4>
      Dr. Wei Luo: <a href="mailto:geowl@nus.edu.sg">geowl@nus.edu.sg</a><br>
      Dr. Zaiyang Ma: <a href="mailto:mzaiyang@outlook.com">mzaiyang@outlook.com</a> &
      <a href="mailto:zy_ma327@126.com">zy_ma327@126.com</a><br>
    </div>
  </div>
</template>

<script>
export default {
  name: '_2022_1'
}
</script>

<style lang="less" scoped>
#_2022_1 {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  line-height: 2rem;
  text-align: justify;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

h4 {
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
}

.home-date {
  font-weight: bold;
  color: grey;
}

a {
  text-decoration: none;
}
</style>