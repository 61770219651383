<template>
  <div id="JoinUs">
    <div>
      <h3 class="cos-strong">Membership</h3>
      <hr>
      <div class="cos-modal">MGSC membership is free of charge. Below are the benefits for MGSC members:
      </div>
      <div class="cos-modal">
        <ul>
          <li>Participation in all IGU-MGSC conferences and awards competition;</li>
          <li>Joining the mailing list to receive updates;</li>
          <li>Free copies of IGU-MGSC international reports presented to IGU-MGSC assemblies and the published
            conference proceedings upon request;</li>
          <li>Access to training courses at times as the need is demonstrated and the financial support is
            available;</li>
          <li>Preferential treatment in exhibitions of IGU-MGSC;</li>
        </ul>
      </div>
    </div>

    <div>
      <h3 class="cos-strong">New Application&nbsp;/&nbsp;Renewal&nbsp;/&nbsp;Update</h3>
      <hr>
      <div class="cos-modal">Membership should be applied through an electronic application form. Membership Application Form should be emailed to Secretary General (Prof. Wei Luo, geowl@nus.edu.sg) and CC to Office Assistant (Dr. Zaiyang Ma, mzaiyang@outlook.com). We will email you a unique Member ID. If you have registered this site,
        you can link the Member ID to your account.<br><br>Your membership needs to be renewed on a yearly basis.
        You can renew your future membership online in the account one month before your membership expires.
      </div>
      <div class="cos-modal">
        <br>
        <svg t="1651920825814" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1402" width="200" height="200">
          <path d="M448 876.8 806.4 512c128-128 57.6-262.4 0-313.6-57.6-57.6-192-128-320 0L172.8 512c-89.6 89.6-38.4 185.6 0 224 38.4 38.4 134.4 89.6 224 0l313.6-313.6c25.6-25.6 32-51.2 32-64 0-38.4-25.6-64-32-70.4-25.6-25.6-83.2-51.2-134.4 0L262.4 601.6c-12.8 12.8-12.8 32 0 44.8 12.8 12.8 32 12.8 44.8 0l313.6-313.6c19.2-19.2 32-12.8 44.8 0 0 0 25.6 19.2 0 44.8l-313.6 313.6c-64 64-121.6 12.8-134.4 0-12.8-12.8-64-70.4 0-134.4l313.6-313.6c108.8-108.8 217.6-12.8 224 0 12.8 12.8 108.8 115.2 0 224L403.2 832l0 0c-12.8 12.8-12.8 32 0 44.8C416 889.6 435.2 889.6 448 876.8L448 876.8 448 876.8C448 876.8 448 876.8 448 876.8 448 876.8 448 876.8 448 876.8z" p-id="1403" fill="#1a80bd"></path>
        </svg>
        <!-- <a href="http://39.98.210.144:8083/data/4c72947c-7f02-46b7-aac6-4e325eb70d24"> Membership Application Form</a> -->
        <a href="http://39.98.210.144:80/data/MGSC Membership application form.docx"> Membership Application Form</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JoinUs'
}
</script>

<style lang="less" scoped>
#JoinUs {
  font-size: 1.2rem;
  line-height: 2rem;
  height: 50rem;
  text-align: justify;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

hr {
  border: 0.1px solid;
  border-color: #eeeeee;
}

svg {
  height: 1.4rem;
  width: 1.4rem;
}
</style>
