<template>
  <div id="_2021_4">
    <div id="The-first-training-course" class="cos-modal">
      <h3><strong>The first training course on Chinese geographical
          analysis models</strong></h3>
      <small class="home-date">Aug 9th-16th, 2021 <br></small>
      <hr>
      <p> &nbsp;&nbsp;&nbsp;&nbsp;The 1st training session on Chinese geographic analysis models was
        successfully held during August 9th-16th, 2021. This training
        session was mainly organized by Nanjing Normal University, the
        Geographical Society of China (GSC), China Society of Natural
        Resources (CSNR), China Association for Geospatial Information
        Society (CAGIS), Chinese National Committee of International Society
        for Digital Earth (ISDE). Our Modeling Geographical Systems
        Commission of the International Geographical Union (IGU) was also
        one of the co-organizers to support the training session. </p>
      <img src="../../../assets/static/img/Events//The-first-training-course.png" style="width: 100%">
      <p> &nbsp;&nbsp;&nbsp;&nbsp;This training session focused on four hydrological models:
        Distributed Time Variant Gain Model (DTVGM) developed by the team of
        Prof. Jun Xia (Wuhan University, the Academician of CAS),
        Uncertainty Quantification Python Laboratory (UQ-PyL) developed by
        the team of Prof. Qingyun Duan (Hohai University),
        Geomorphology-Based Hydrological Model (GBHM) developed by the team
        of Prof. Dawen Yang(Tsinghua University), and Spatially Explicit
        Integrated Modeling System (SEIMS) developed by the team of Prof.
        Junzhi Liu (Nanjing Normal University, Lanzhou University). There
        were 1280 students from 384 universities, institutes, and companies
        in 10 nations attending the online training session.</p>
      <p> &nbsp;&nbsp;&nbsp;&nbsp;The success of this training session can promote the construction of
        the Chinese geographic model ecosystem and the open and sustainable
        geographic model community. It can also facilitate the model
        reusing, knowledge sharing, and collaborative geographic research.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: '_2021_4'
}
</script>

<style>
#_2021_4 {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  line-height: 2rem;
  text-align: justify;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

.home-date {
  font-weight: bold;
  color: grey;
}
</style>