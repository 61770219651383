<template>
  <div id="_2021_5">
    <div id="IGU-19th-International" class="cos-modal">
      <h3><strong class="home-h">IGU 19th International Conference on Spatial Data
          Handling and Geographic Intelligence</strong></h3>
      <small class="home-date">Aug 13th-14th, 2021 <br></small>
      <hr>
      <p>During August 13-14, 2021, the 19th International Conference on
        Spatial Data Handling and Geographic Intelligence was successfully
        held. The IGU Comission on Geographical Information Science and
        Commission on Modeling Geographical Systems co-organized this
        conference.</p>
      <p style="text-align:center">
        <img src="../../../assets/static/img/Events/IGU-19th-International.png">
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: '_2021_5'
}
</script>

<style>
#_2021_5 {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  line-height: 2rem;
  text-align: justify;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

.home-date {
  font-weight: bold;
  color: grey;
}

p > img {
  width: 70%;
}
</style>