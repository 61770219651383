<template>
  <div id="JobsOpportunities">
    <div>
      <div>
        <br>
        <strong>Research Assistant Professor positions open in Hong Kong Polytechnic University</strong>
        <hr>
        <p>Hong Kong Polytechnic University<br>Hong Kong, China</p>
        <a href="https://www.polyu.edu.hk/hro/postspec/19110601.pdf" target="_blank" style="color: white; text-decoration: none;">
          <el-button round>View</el-button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JobsOpportunities'
}
</script>

<style lang="less" scoped>
#JobsOpportunities {
  font-size: 1.2rem;
  line-height: 2rem;
  height: 50rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  text-align: justify;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

hr {
  border: 0.1px solid;
  border-color: #eeeeee;
}

a {
  display: block;
  width: 4rem !important;
}
</style>