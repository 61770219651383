<!--
 * @Author: ZhangS
 * @LastEditors: ZhangS
 * @Date: 2023-11-06 16:36:55
 * @LastEditTime: 2023-11-06 20:12:20
 * @Description: 
-->
<template>
    <div id="_2022_5">
      <div id="Call-for-Nominations">
        <h3><strong class="home-h">Announcement of IGU MGSC Awards 2023
          </strong>
        </h3>
  
        <hr>
        <p>
            After careful consideration by the awards committee, the IGU MGSC Awards 2023 were announced.
        </p>
        <p>
            These awards were organized and judged by the Modeling Geographical Systems Commission of IGU. 
            They aim to recognize outstanding academic contributions in the field of geographic modeling research and promote innovation and development in modeling geographic systems.
        </p>
        <p>
            This time, one winner of the Outstanding Achievement Award (OAA) and two winners of the Excellent Young Scholar Award (YSA) were selected. The awardee list is as follows:
        </p>
        <strong>
            OAA in Modeling Geographical Systems
        </strong>
        <p>
            Prof. Mei-Po Kwan
        </p>
        <div class="pic" >
            <img style="max-width:100%;" src="../../../assets/static/img/Events/Prof. Mei-Po Kwan.jpg">
        </div>
        <p>
            The Chinese University of Hong Kong, Hong Kong China
        </p>
        <strong>
            YSA in Modeling Geographical Systems
        </strong>
        <div class="flex">
            <div class="first" style="width: fit-content;margin-right: 40px;">
                <p>
                    Dr. Xiao Huang
                </p>
                <div class="pic">
                    <img style="max-width: 100%;" src="../../../assets/static/img/Events/Dr. Xiao Huang.jpg">
                </div>
                <p>
                    Emory University, US
                </p>

            </div>
            <div class="second" style="width:fit-content;">
                <p>
                    Dr. Jian Lin
                </p>
                <div class="pic">
                    <img style="max-width: 100%;" src="../../../assets/static/img/Events/Dr. Jian Lin.jpg"><br>
                </div>
                <p>
                    The Chinese University of Hong Kong, Hong Kong China
                </p>
            </div>
        </div>


      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: '_2022_5'
  }
  </script>
  
  <style lang="less" scoped>
  #_2022_5 {
    height: fit-content;
    font-size: 1.2rem;
    line-height: 2rem;
    width: 65%;
    margin: 0 auto;
    position: relative;
    word-spacing: 0.3rem;
    margin-top: 2.2rem;
    line-height: 2rem;
    text-align: justify;
  }
  
  h3 {
    color: #1a80b6;
    font-size: 1.3rem;
    font-weight: 1000;
  }
  
  h4 {
    margin-top: 0.9rem;
    margin-bottom: 0.9rem;
  }
  
  .home-date {
    font-weight: bold;
    color: grey;
  }
  
  a {
    text-decoration: none;
  }
  
  .pic {
    // display: table-cell;
    text-align: center;
    height: 128px;
    width: 128px;
    overflow: hidden;
  }
  .flex{
    display: flex;
    // justify-content: center;
  }
  </style>