import Home from '../components/Home.vue'
import AboutUs from '../components/AboutUs.vue'
import ContactUs from '../components/ContactUs.vue'
import Awards from '../components/Awards.vue'
import Journals from '../components/Publication/Journals.vue'
import SpecialIssues from '../components/Publication/SpecialIssues.vue'
import MembersPapers from '../components/Publication/MembersPapers.vue'
import JobsOpportunities from '../components/JobsOpportunities.vue'
import JoinUs from '../components/JoinUs.vue'
import Events from '../components/Events.vue'
import Login from '../components/Login.vue'
import _2020_1 from '../components/Events/2020/1.vue'
import _2021_1 from '../components/Events/2021/1.vue'
import _2021_2 from '../components/Events/2021/2.vue'
import _2021_3 from '../components/Events/2021/3.vue'
import _2021_4 from '../components/Events/2021/4.vue'
import _2021_5 from '../components/Events/2021/5.vue'
import _2021_6 from '../components/Events/2021/6.vue'
import _2022_1 from '../components/Events/2022/1.vue'
import _2022_2 from '../components/Events/2022/2.vue'
import _2022_3 from '../components/Events/2022/3.vue'
import _2022_4 from '../components/Events/2022/4.vue'
import _2022_5 from '../components/Events/2022/5.vue'
import _2023_1 from '../components/Events/2023/1.vue'
import _2023_2 from '../components/Events/2023/2.vue'
import _2023_3 from '../components/Events/2023/3.vue'
import _2024_1 from '../components/Events/2024/1.vue'
import _2024_2 from '../components/Events/2024/2.vue'
import UserSpace from '../components/UserSpace.vue'
import MemberSpace from '../components/MemberSpace.vue'

export default [
	{
		path: '/',
		component: Home,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Home',
		component: Home,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/AboutUs',
		component: AboutUs,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/ContactUs',
		component: ContactUs,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Awards',
		component: Awards,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Publication/Journals',
		component: Journals,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Publication/SpecialIssues',
		component: SpecialIssues,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Publication/MembersPapers',
		component: MembersPapers,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/JobsOpportunities',
		component: JobsOpportunities,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/JoinUs',
		component: JoinUs,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Events',
		component: Events,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Login',
		component: Login,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Events/2020/1',
		component: _2020_1,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Events/2021/1',
		component: _2021_1,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Events/2021/2',
		component: _2021_2,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Events/2021/3',
		component: _2021_3,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Events/2021/4',
		component: _2021_4,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Events/2021/5',
		component: _2021_5,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Events/2021/6',
		component: _2021_6,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Events/2022/1',
		component: _2022_1,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Events/2022/2',
		component: _2022_2,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Events/2022/3',
		component: _2022_3,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Events/2022/4',
		component: _2022_4,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Events/2022/5',
		component: _2022_5,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Events/2023/1',
		component: _2023_1,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Events/2023/2',
		component: _2023_2,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Events/2023/3',
		component: _2023_3,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Events/2024/1',
		component: _2024_1,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/Events/2024/2',
		component: _2024_2,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/UserSpace',
		component: UserSpace,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '/MemberSpace',
		component: MemberSpace,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	},
	{
		path: '*',
		component: Home,
		meta: {
			title: 'MGSC of IGU- Modeling Geographical Systems Commission International Geographical Union (IGU)'
		}
	}
]
