<template>
  <div id="_2021_2">
    <div id="The-2nd-national-forum" class="cos-modal">
      <h3><strong>The 2nd national forum on geographic modeling and simulation (and the launch ceremony of the training course on domestic geographical analysis models)</strong></h3>
      <small class="home-date">Jan 16th-17th, 2021<br></small>
      <hr>
      <p> &nbsp;&nbsp;&nbsp;&nbsp;The 2nd national forum on geographic modeling and simulation (and the launch ceremony of the training course on domestic
        geographical analysis models) was successfully held in Nanjing Normal University during Jan 16th-17th, 2021. This forum
        was mainly organized by Key Laboratory of the Virtual Geographic Environment, Ministry of Education of PRC and School of Geography,
        Nanjing Normal University with several co-organizers including the Geographical Society of China (GSC), China Society of Natural
        Resources (CSNR), China Association for Geospatial Information Society (CAGIS), Chinese National Committee of International Society
        for Digital Earth (ISDE), International Environmental Modelling and Software Society (iEMSs), Modeling Geographical Systems Commission
        of International Geographical Union (IGU), Commission on Geospatial Analysis and Modeling of International Cartographic Association (ICA),
        Young Geographer Working Group of Asian Geographical Association (AGA), Jiangsu Center for Collaborative Innovation in Geographical Information
        Resource Development and Application, and Yangtze River Delta State Sub-center of National Earth System Science Data Center. Due to the
        prevention and control measures of COVID-2019, the forum was synchronously conducted in the onsite and offsite way with the support of
        Tencent Meeting, ZOOM, and Bilibili Live. <br></p>

      <div style="margin-top: 2%;margin-bottom: 2%;">
        <img src="../../../assets/static/img/Events/The-2nd-national-forum-1.png" style="width: 100%">
      </div>

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;The forum began with the opening ceremony hosted by Prof. Linwang Yuan (the Dean of the School of Geography) on
        Jan 16th. Prof. Guoxiang Chen, the President of Nanjing Normal University, Prof. R. B. Singh, the
        Secretary General and Treasurer of IGU, Prof. Stefan Reis, the President of iEMSs, Prof. Guoyou Zhang,
        the Vice President and Secretary General of GSC, Prof. Shengkui Cheng, the President of CSNR, Prof. Zengning Wang,
        the Secretary General of CAGIS, and Prof. Jiantao Bi, the Deputy Secretary General of Chinese National Committee
        of ISDE were invited to give speeches. Moreover, Prof. Huijun Wang, the Academician of Chinese Academy of Sciences
        (CAS), Prof. Huayu Lu, the Vice President of GSC, Prof. Lijie Pu, the Vice President of CSNR, Prof. Guonian Lv,
        the Academic Leader of Geography Sciences of Nanjing Normal University and Prof. Min Chen, the Chair of Modeling
        Geographical Systems Commission, IGU presented the opening ceremony.<br>

        &nbsp;&nbsp;&nbsp;&nbsp;During the forum, four Academicians of CAS, Prof. Huijun Wang from Nanjing University of
        Information Science & Technology, Prof. Peng Cui from Institute of Mountain Hazards and
        Environment (IMHE), CAS, Prof. Bojie Fu from Beijing Normal University, Prof. Jun Xia from Wuhan
        University, as well as Prof. Jia Wang from Division I, Department of Earth Sciences, National
        Natural Science Foundation of China, Prof. Jinshui Wu from the Institute of Subtropical Agriculture
        (ISA), CAS, Prof. Yao Huang from the Institute of Botany, CAS, Prof, Wenjie Dong from School of
        Atmospheric Sciences, Sun Yat-sen University, Prof. Bin Wang from Institute of Atmospheric Physics,
        CAS, Prof. Xiangzheng Deng from the Institute of Geographic Sciences and Natural Resources Research,
        CAS, Prof. Xia Li from School of Geography Sciences, East China Normal University, Prof. Tianxiang
        Yue from the Institute of Geographic Sciences and Natural Resources Research, CAS, Prof. Jinfeng Wang
        from the Institute of Geographic Sciences and Natural Resources Research, CAS, Prof. Weimin Ju from the
        International Institute of Earth System Science, Nanjing University, Prof. Xin Li from the Institute of
        Tibetan Plateau Research, CAS, Prof. Chunmiao Zheng from School of Environmental Science and Engineering,
        Southern University of Science and Technology, Prof. Dawen Yang from School of Civil Engineering,
        Tsinghua University, Prof. Qingyun Duan from College of Hydrology and Water Resources, Hohai University,
        and Prof. Chuanhai Wang from College of Hydrology and Water Resources, Hohai University were invited to
        attend the forum. They gave presentations to share their work and achievements in the related domains of
        geographic modeling and simulation together with Prof. A-Xing Zhu and Prof. Min Chen who are from School
        of Geography, Nanjing Normal University. Because of these brilliant presentations, a large number of
        researchers and students (Up to 20 thousand people) are attracted to watch the forum live on the web.<br>

        &nbsp;&nbsp;&nbsp;&nbsp;In addition, the training course on domestic geographical analysis models was launched in this forum.
        President Guoxiang Chen, Prof. Huijun Wang, Prof. Huayu Lu, Prof. Lijie Pu, Prof. Zengning Wang
        and Prof. Jiantao Bi attended the unveiling ceremony. Then, academician Prof. Xia’s team Jun,
        Prof. Qingyun Duan’s team, Prof. Dawen Yang’s team, and Dr. Junzhi Liu were invited as tutors
        of the first training course and attended the appointment ceremony.
      </p>
      <div style="margin-top: 2%;margin-bottom: 2%;">
        <img src="../../../assets/static/img/Events/The-2nd-national-forum-2.jpg" style="width: 100%">
      </div>
      <div style="margin-top: 2%;margin-bottom: 2%;">
        <img src="../../../assets/static/img/Events/The-2nd-national-forum-3.jpg" style="width: 100%">
      </div>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;The National Geographic Modeling and Simulation Forum, which was founded in 2017 by Nanjing Normal University, aims to explore cutting-edge progress and scientific breakthroughs in the field of geographic modeling and simulation and to provide a unique platform for sharing scientific research achievements, exchange academic ideas, and strengthening academic cooperation among Chinese geographical modelers. Focusing on the development of domestic geographical analysis models and modeling platforms, this forum calls on Chinese geographical modelers to work together to develop domestic geographical analysis models and to build an ecological circle of autonomous model systems for the target of constructing Chinese autonomous geographical simulators and promoting the international researches in the fields of geographic modeling and simulation.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: '_2021_2'
}
</script>

<style>
#_2021_2 {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  line-height: 2rem;
  text-align: justify;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

.home-date {
  font-weight: bold;
  color: grey;
}
</style>