<template>
  <div id="_2022_5">
    <div id="Call-for-Nominations">
      <h3>
        <strong class="home-h"
          >Call for Nominations - IGU Outstanding Achievement Award and
          Excellent Young Scholar Award in Modeling Geographical Systems (2024)
        </strong>
      </h3>

      <hr />
      <p>
        The
        <strong
          >IGU Outstanding Achievement Award and Excellent Young Scholar Award
          in Modeling Geographical Systems (OAA 2024 and YSA 2024)</strong
        >
        are receiving nominations to recognize researchers for long and
        meritorious contributions to the community of geographic modeling
        research or young researchers for meritorious academic contributions in
        their early career. An awards committee will be organized by IGU through
        its Modeling Geographical Systems Commission (IGU MGSC), and it will
        solicit and evaluate the nominees and presents the awards to the
        individuals in light of their excellent scientific achievements and
        contributions.
      </p>
      <strong> Eligibility </strong>
      <ul>
        <li>
          Nominees for the OAA 2024 are expected to have outstanding research
          contributions to geographic modeling and relevant fields.
        </li>
        <li>
          Nominees for the YSA 2024 should have received a doctoral degree in
          the last seven years (no earlier than 2017) and have significant
          research contributions to geographic modeling and relevant fields.
        </li>
        <li>Nominees for the YSA 2024 also should be IGU MGSC members.</li>
      </ul>
      <strong> Award Benefits </strong>
      <p>
        The award committee will select one awardee for OAA 2024, and the
        awardee will receive an award certificate and cash prizes (USD $500).
        <br />The award committee will select two awardees for YSA 2024, and
        each awardee will receive an award certificate and cash prizes (USD
        $300).
      </p>
      <strong> Submission Instructions </strong>
      <p>
        To nominate an award for OAA 2024, please submit the following documents
        as PDF files:
      </p>
      <ul>
        <li>
          <strong>Nomination Letter:</strong>A personal statement is needed to
          describe the nominee’s contributions and achievements. The statement
          should be no more than 500 words.
        </li>
        <li>
          <strong>Curriculum Vitae or Resume:</strong>The latest curriculum
          vitae or resume of nominees.
        </li>
      </ul>
      <p>
        To nominate an award for YSA 2024, please submit the following documents
        as PDF files:
      </p>
      <ul>
        <li>
          <strong>Nomination Letter:</strong>A personal statement is needed to
          describe the nominee’s contributions and achievements. The statement
          should be no more than 500 words.
        </li>
        <li>
          <strong>Curriculum Vitae or Resume:</strong>The latest curriculum
          vitae or resume of nominees.
        </li>
        <li>
          <strong>Certificate of Academic Degree:</strong>The certificate can
          prove the eligibility of nominees.
        </li>
        <li>
          <strong>Two Recommendation Letter:</strong>The recommender must have a
          formal professional role that allows them to assess the nominee’s
          achievements, current academic status and progress. The recommendation
          letter should be signed by the recommenders. Once prepared, the letter
          should be converted to a PDF format.
        </li>
      </ul>
      <p>
        The Nomination Letter Template can be found under the “Awards” page of
        the official website of IGU MGSC (<a
          href="http://www.igu-geomodeling.com/Awards"
          >http://www.igu-geomodeling.com/Awards</a
        >).
      </p>
      <!-- <p>
        Nominators or nominees need to merge the mentioned documents into a
        single PDF file and submit it to Zaiyang Ma (<a
          href="mailto:mzaiyang@outlook.com"
          style="color: orange"
        >
          mzaiyang@outlook.com
        </a>
        or
        <a href="mailto:zy_ma327@126.com" style="color: orange">
          zy_ma327@126.com
        </a>
        ), and include “IGU MGSC Outstanding Achievement Award” or “IGU MGSC
        Excellent Young Scholar Award” in the email subject line.
        <strong>(Self-nominations are not accepted.)</strong>
      </p> -->
      <p>
        Nominators or nominees need to merge the mentioned documents into a
        single PDF file. Please submit the PDF file to Chair of MGSC, Prof. Min
        Chen, and cc to Dr. Zaiyang Ma (<a
          href="mailto:mzaiyang@outlook.com"
          style="color: orange"
        >
          mzaiyang@outlook.com
        </a>
        or
        <a href="mailto:zy_ma327@126.com" style="color: orange">
          zy_ma327@126.com </a
        >), including “IGU MGSC Outstanding Achievement Award” or “IGU MGSC
        Excellent Young Scholar Award” in the email subject line.
        <strong>(Self-nominations are not accepted.)</strong>
      </p>

      <strong> Important date </strong>
      <p>
        The tentative dates for submission and notifications are as follows.
      </p>
      <ul>
        <li>Deadline for submission: July 31, 2024</li>
        <li>Deadline for notification to the awardee: August 20, 2024</li>
      </ul>
      <p>
        The award ceremony will be held in the
        <strong>
          3rd International Workshop on Geographic Modeling and Simulation </strong
        >(
        <a href="https://geomodeling.njnu.edu.cn/iwgms2024"
          >https://geomodeling.njnu.edu.cn/iwgms2024</a
        >).
      </p>
      <strong>Contact</strong>
      <p>
        Dr. Wei Luo:<a href="mailto:geowl@nus.edu.sg" style="color: orange">
          geowl@nus.edu.sg
        </a>
        <br />
        Dr. Zaiyang Ma:<a
          href="mailto:mzaiyang@outlook.com"
          style="color: orange"
        >
          mzaiyang@outlook.com </a
        >&<a href="mailto:zy_ma327@126.com" style="color: orange">
          zy_ma327@126.com
        </a>
      </p>
      <div style="margin-top: 10px">
        <a
          href="http://39.98.210.144:80/data/IGU MGSC Award Nomination Letter-2024.docx"
          >IGU MGSC Award Nomination Letter-2024</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '_2024_2'
};
</script>
<style scoped lang="less">
#_2022_5 {
  height: fit-content;
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  line-height: 2rem;
  text-align: justify;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

h4 {
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
}

.home-date {
  font-weight: bold;
  color: grey;
}

a {
  text-decoration: none;
}

.pic {
  // display: table-cell;
  text-align: center;
  height: 128px;
  width: 128px;
  overflow: hidden;
}
.flex {
  display: flex;
  // justify-content: center;
}
</style>
