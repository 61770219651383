<template>
  <div id="_2022_4">
    <div id="Call-for-Nominations">
      <h3><strong class="home-h">The session of Geographic information modelling and simulation in the era of big data in IGU Paris 2022
        </strong></h3>
      <hr>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;The centennial congress of the International Geographical Union (IGU) was held in Paris from June 18 to 22, 2022. In this congress, Modeling Geographical Systems Commission (MGSC) of IGU organized a virtual session–Geographic information modelling and simulation in the era of big data–to promote idea exchange and achievement sharing in this field. 7 experts and scholars attended this session and gave presentations. Several topics, including Substantial Development, Big Data and Climate Change, Plant Nitrogen Content Estimation, Land Value Uplift, Model Sharing and Reuse, Rooftop Solar Potential Evaluation, and Geographic Simulation Reproduction, were discussed by attendees.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: '_2022_4'
}
</script>

<style lang="less" scoped>
#_2022_4 {
  height: 700px;
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  line-height: 2rem;
  text-align: justify;
}

h3 {
  color: #1a80b6;
  font-size: 1.3rem;
  font-weight: 1000;
}

h4 {
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
}

.home-date {
  font-weight: bold;
  color: grey;
}

a {
  text-decoration: none;
}
</style>